<template>
    <div class="intro-atag">
        <div class="c-intropage__hero">
            <img src="/img/atag/intro-atag-hero.jpg" class="img-cover" alt="Atag hero image" />
            <div class="c-intropage__hero--overlay">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-10 offset-lg-1">
                            <div class="c-intropage__hero--title text-center">
                                <h1 v-html="getTitle"></h1>
                                <h2 class="c-wizard__title--sub" v-if="getSubtitle != ''" v-html="getSubtitle"></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="c c-intropage__content">
            <div class="container">
                <ul class="c-grid-usplist">
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" width="38.75" height="38.75" viewBox="0 0 38.75 38.75">
                            <path
                                fill="#b0ada0"
                                d="M19.375 0A19.372 19.372 0 0 0 0 19.375 19.372 19.372 0 0 0 19.375 38.75 19.372 19.372 0 0 0 38.75 19.375 19.372 19.372 0 0 0 19.375 0Zm7.226 24.453-1.563 1.953a1.248 1.248 0 0 1-.977.47 1.246 1.246 0 0 1-.78-.275l-5.234-3.884a3.117 3.117 0 0 1-1.172-2.442V8.125a1.251 1.251 0 0 1 1.25-1.25h2.5a1.251 1.251 0 0 1 1.25 1.25v11.25l4.531 3.32a1.248 1.248 0 0 1 .47.977 1.247 1.247 0 0 1-.275.781Z"
                            />
                        </svg>
                        <span v-html="$tr('intropage.header.blocks.item1')"></span>
                    </li>
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="40" viewBox="0 0 50 40">
                            <path
                                fill="#b0ada0"
                                d="M14.375 10h-3.75a.627.627 0 0 0-.625.625v18.75a.627.627 0 0 0 .625.625h3.75a.627.627 0 0 0 .625-.625v-18.75a.627.627 0 0 0-.625-.625ZM11.25 35H5v-6.25a1.254 1.254 0 0 0-1.25-1.25h-2.5A1.254 1.254 0 0 0 0 28.75v10A1.254 1.254 0 0 0 1.25 40h10a1.254 1.254 0 0 0 1.25-1.25v-2.5A1.254 1.254 0 0 0 11.25 35Zm8.125-25h-1.25a.627.627 0 0 0-.625.625v18.75a.627.627 0 0 0 .625.625h1.25a.627.627 0 0 0 .625-.625v-18.75a.627.627 0 0 0-.625-.625ZM1.25 12.5h2.5A1.254 1.254 0 0 0 5 11.25V5h6.25a1.254 1.254 0 0 0 1.25-1.25v-2.5A1.254 1.254 0 0 0 11.25 0h-10A1.254 1.254 0 0 0 0 1.25v10a1.254 1.254 0 0 0 1.25 1.25ZM31.875 10h-3.75a.627.627 0 0 0-.625.625v18.75a.627.627 0 0 0 .625.625h3.75a.627.627 0 0 0 .625-.625v-18.75a.627.627 0 0 0-.625-.625Zm-7.5 0h-1.25a.627.627 0 0 0-.625.625v18.75a.627.627 0 0 0 .625.625h1.25a.627.627 0 0 0 .625-.625v-18.75a.627.627 0 0 0-.625-.625ZM48.75 0h-10a1.254 1.254 0 0 0-1.25 1.25v2.5A1.254 1.254 0 0 0 38.75 5H45v6.25a1.254 1.254 0 0 0 1.25 1.25h2.5A1.254 1.254 0 0 0 50 11.25v-10A1.254 1.254 0 0 0 48.75 0Zm0 27.5h-2.5A1.254 1.254 0 0 0 45 28.75V35h-6.25a1.254 1.254 0 0 0-1.25 1.25v2.5A1.254 1.254 0 0 0 38.75 40h10A1.254 1.254 0 0 0 50 38.75v-10a1.254 1.254 0 0 0-1.25-1.25ZM40 29.375v-18.75a.627.627 0 0 0-.625-.625h-3.75a.627.627 0 0 0-.625.625v18.75a.627.627 0 0 0 .625.625h3.75a.627.627 0 0 0 .625-.625Z"
                            />
                        </svg>
                        <span v-html="$tr('intropage.header.blocks.item2')"></span>
                    </li>
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 30 40">
                            <path
                                d="M26.25-30H20a5,5,0,0,0-5-5,5,5,0,0,0-5,5H3.75A3.752,3.752,0,0,0,0-26.25V1.25A3.752,3.752,0,0,0,3.75,5h22.5A3.752,3.752,0,0,0,30,1.25v-27.5A3.752,3.752,0,0,0,26.25-30ZM15-31.875A1.876,1.876,0,0,1,16.875-30,1.876,1.876,0,0,1,15-28.125,1.876,1.876,0,0,1,13.125-30,1.876,1.876,0,0,1,15-31.875ZM15-20a5,5,0,0,1,5,5,5,5,0,0,1-5,5,5,5,0,0,1-5-5A5,5,0,0,1,15-20ZM23.75-1.5A1.638,1.638,0,0,1,22,0H8A1.638,1.638,0,0,1,6.25-1.5V-3c0-2.485,2.351-4.5,5.25-4.5h.391A8.034,8.034,0,0,0,15-6.879,8.089,8.089,0,0,0,18.117-7.5h.391c2.9,0,5.25,2.015,5.25,4.5Z"
                                transform="translate(0 35)"
                                fill="#b0ada0"
                            />
                        </svg>
                        <span v-html="$tr('intropage.header.blocks.item3')"></span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="c c-intropage__content">
            <div class="container">
                <div class="c-intropage__content--inner">
                    <div class="row gutters-lg-0">
                        <div class="col-12 col-lg-6 d-none d-lg-block">
                            <div class="c c-imgblock">
                                <img src="/img/atag/intro-atag1.jpg" alt="Atag 1" loading="lazy" class="img-fluid" />
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="c c-intropage__text bg-white">
                                <template>
                                    <h2><span v-html="$tr('intropage.block1.title')"></span></h2>
                                    <p>
                                        <span v-html="$tr('intropage.block1.subtext')"></span>
                                    </p>
                                    <h3><span v-html="$tr('intropage.block1.list.title')"></span></h3>
                                    <ul class="c-arrowlist">
                                        <li>
                                            <span v-html="$tr('intropage.block1.list.item1')"></span>
                                        </li>
                                        <li>
                                            <span v-html="$tr('intropage.block1.list.item2')"></span>
                                        </li>
                                    </ul>
                                </template>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="c c-intropage__text bg-white">
                                <template>
                                    <h2><span v-html="$tr('intropage.block2.title')"></span></h2>
                                    <p>
                                        <span v-html="$tr('intropage.block2.text1')"></span>
                                    </p>
                                    <p>
                                        <span v-html="$tr('intropage.block2.text2')"></span>
                                    </p>
                                    <p>
                                        <span v-html="$tr('intropage.block2.text3')"></span>
                                    </p>
                                    <p>
                                        <span v-html="$tr('intropage.disclaimerlinks')"></span>
                                    </p>
                                </template>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 d-none d-lg-block">
                            <div class="c c-imgblock">
                                <img src="/img/atag/intro-atag2.jpg" alt="Atag 2" loading="lazy" class="img-fluid" />
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="c-intropage__text bg-fade-1 text-center">
                                <h2 class="title-2k"><span v-html="$tr('intropage.block3.title')"></span></h2>
                                <img
                                    src="/img/atag/intro-atag-5years-fr.png"
                                    v-if="lang === 'fr'"
                                    height="166"
                                    width="409"
                                    alt="5 ans de garantie"
                                    loading="lazy"
                                    class="img-fluid"
                                />
                                <img
                                    src="/img/atag/intro-atag-5years-be.png"
                                    v-else-if="lang === 'be'"
                                    height="161"
                                    width="409"
                                    alt="5 jaar garantie"
                                    loading="lazy"
                                    class="img-fluid"
                                />
                                <img
                                    src="/img/atag/intro-atag-5years-nl.png"
                                    v-else
                                    height="161"
                                    width="409"
                                    alt="5 jaar garantie"
                                    loading="lazy"
                                    class="img-fluid"
                                />
                                <template>
                                    <p>
                                        <span v-html="$tr('intropage.block3.text1')"></span>
                                    </p>
                                </template>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="c c-intropage__text bg-fade-2 text-center">
                                <img
                                    src="/img/atag/intro-atag-8years-fr.png"
                                    v-if="lang === 'fr'"
                                    height="166"
                                    width="410"
                                    alt="8 ans de garantie"
                                    loading="lazy"
                                    class="img-fluid"
                                />
                                <img
                                    src="/img/atag/intro-atag-8years-be.png"
                                    v-else-if="lang === 'be'"
                                    height="161"
                                    width="410"
                                    alt="8 jaar garantie"
                                    loading="lazy"
                                    class="img-fluid"
                                />
                                <img
                                    src="/img/atag/intro-atag-8years-nl.png"
                                    v-else
                                    height="161"
                                    width="410"
                                    alt="8 jaar garantie"
                                    loading="lazy"
                                    class="img-fluid"
                                />
                                <template>
                                    <p>
                                        <span v-html="$tr('intropage.block3.text2')"></span>
                                    </p>
                                </template>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="c c-intropage__text bg-white">
                                <p><span v-html="$tr('intropage.bottom.text')"></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-stickybtn">
            <button @click="$emit('start')" class="btn btn-outline-secondary">
                <span v-html="$tr('wizard.button.register')"></span>
                <i class="fal fa-arrow-right"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'intropage-atag',
    data() {
        return {};
    },
    props: {
        lang: {
            type: String,
            required: true
        }
    },
    mounted() {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        let product = params.get('modelnummer');
        if (product) {
            this.$emit('start');
        }
    },
    computed: {
        getTitle() {
            return this.$tr('intropage.title');
        },
        getSubtitle() {
            return this.$tr('intropage.subtitle');
        }
    }
};
</script>
