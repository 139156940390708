import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import store from './store';
import garantiekaart from './modules/forms/garantiekaart.vue';
import Vuelidate from 'vuelidate';
import vuexI18n from 'vuex-i18n';
import Autocomplete from 'vue2-autocomplete-js';

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.component('autocomplete', Autocomplete);
Vue.use(require('vue-moment'));

// require('../../../dist/style/vue2-autocomplete.css');

Vue.use(vuexI18n.plugin, store, {
    moduleName: 'i18n',
    onTranslationNotFound(locale, key) {
        locale += '-' + store.state.brand;
        if (store.state.langKeysSearching[key]) {
            return `!${key}!`;
        }
        store.state.langKeysSearching[key] = true;
        console.warn(`i18n :: Key '${key}' not found for locale '${locale}'`);
        return new Promise((resolve, reject) => {
            store
                .dispatch('setTranslationForKey', { locale: locale, key: key })
                .then(() => {
                    if (store.state.i18n.translations[locale][key] === undefined) {
                        resolve(`!${key}!`);
                        store.state.langKeysSearching[key] = false;
                    } else {
                        resolve(store.state.i18n.translations[locale][key]);
                        store.state.langKeysSearching[key] = false;
                    }
                })
                .catch(e => {
                    reject(e);
                });
        });
    }
});

Vue.mixin({
    methods: {
        $tr(key, optionalVariable, defaultFallback) {
            let translation = this.$t(key, optionalVariable, 'no_translation_found');
            if (translation === 'no_translation_found' || translation === '!' + key + '!') {
                if (defaultFallback) {
                    return defaultFallback;
                } else {
                    return '[' + key + ']';
                }
            }
            return translation;
        },
        IsPromotionSelected(promotionId, typenumber) {
            // when the url has a promotion the promotion is always selected
            if (this.promotionFoundInUrl) {
                return true;
            }
            if (promotionId && typenumber) {
                var promotion = this.promotionDtos.find(x => x.id === promotionId && x.typeNumbers[0] === typenumber);
                return promotion.selected;
            }
            return false;
        },
        PromotionIsFromDealer(promotionId, dealername, city) {
            // when the url has a promotion the promotion is always selected
            if (this.promotionFoundInUrl) {
                return false;
            }
            if (promotionId && dealername && city) {
                let promotion = this.promotionDtos.find(promotion => promotion.id === promotionId);
                let dealer = promotion.dealers.find(dealer => dealer.accountnaam === dealername && dealer.city === city);
                if (dealer === undefined) {
                    return false;
                } else {
                    return true;
                }
            }
            return false;
        }
    }
});

const router = new VueRouter({
    mode: 'history'
});

function init() {
    const elGarantiekaart = document.getElementById('garantiekaart');

    if (elGarantiekaart !== null && elGarantiekaart !== undefined) {
        let brand = elGarantiekaart.getAttribute('data-brand');
        let language = elGarantiekaart.getAttribute('data-language');
        let country = elGarantiekaart.getAttribute('data-country');
        let promotion = elGarantiekaart.getAttribute('data-promotion');
        let url = elGarantiekaart.getAttribute('data-url');

        // ReSharper disable once ConstructorCallNotUsed
        new Vue({
            el: '#garantiekaart',
            router,
            store,
            render: h =>
                h(garantiekaart, {
                    props: {
                        brand: brand,
                        language: language,
                        country: country,
                        promotion: promotion,
                        url: url
                    }
                })
        });
    }
}

export default {
    init
};
