<template>
    <div class="c-requiredfieldexplanation">
        <p>{{ $tr('general.isrequiredexplanation') }}</p>
    </div>
</template>

<script>
export default {
    name: 'required-field-explanation',
    data() {
        return {};
    }
};
</script>
