import axios from 'axios';

export default {
    setLanguage(context, payloadLocale) {
        const splitPayload = payloadLocale.split('-');
        const locale = splitPayload[0];
        const country = splitPayload[1];
        const brand = splitPayload[2];
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/translations/GetTranslations?locale=${locale}&brand=${brand}&country=${country}`)
                .then(response => {
                    context.commit('setLanguage', { locale: payloadLocale, translations: response.data });
                    resolve();
                })
                .catch(e => {
                    console.log('Something went wrong retrieving translations for locale!', e);
                    reject(e);
                });
        });
    },
    setTranslationForKey(context, payload) {
        return new Promise((resolve, reject) => {
            const splitPayload = payload.locale.split('-');
            const locale = splitPayload[0];
            const country = splitPayload[1];
            const brand = splitPayload[2];
            axios
            
                .get(`/api/translations/GetTranslation?locale=${locale}&brand=${brand}&country=${country}&key=${payload.key}`)
                .then(response => {
                    if (response.data) {
                        context.commit('setTranslation', { locale: payload.locale, key: payload.key, value: response.data });
                    }
                    resolve();
                })
                .catch(e => {
                    console.log('Something went wrong retrieving translation value!', e);
                    reject(e);
                });
        });
    },
    setBrand(context,payload){
        context.commit('setBrand',payload);
    }
};
