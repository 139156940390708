<template>
    <div class="c c-confirm">
        <div class="c c-stap">
            <h3 class="c-stap__header">{{ $tr('wizard.checkstep.title') }}</h3>
            <div class="c-stap__body mt-1">
                <p>{{ $tr('wizard.checkstep.introtext') }}</p>
            </div>
            <div class="c-stap__badge">
                <div>{{ $tr('wizard.checkstep.badge') }}</div>
            </div>
        </div>

        <div class="c c-stap">
            <div class="c-stap__header">
                <h3>{{ $tr('wizard.checkstep.personal.title') }}</h3>
                <button class="btn btn-link btn-hasicon" @click="$emit('editPersonalData')">
                    <i class="fal fa-edit"></i>
                    <span>{{ $tr('wizard.button.edit') }}</span>
                </button>
            </div>
            <div class="c-stap__body">
                <FormField :label="$tr('step1.salutation')" :value="getSexe" />
                <FormField :label="$tr('step1.lastname')" :value="userData.name" />
                <FormField :label="$tr('step1.address')" :value="getAddress" />
                <FormField :label="$tr('step1.email')" :value="userData.emailaddress" />
                <FormField :label="$tr('step1.phone')" :value="userData.phonenumber" />
            </div>
        </div>

        <div class="c c-stap">
            <div class="c-stap__header">
                <h3>{{ $tr('wizard.checkstep.purchase.title') }}</h3>
                <button class="btn btn-link btn-hasicon" @click="$emit('editPurchaseData')">
                    <i class="fal fa-edit"></i>
                    <span>{{ $tr('wizard.button.edit') }}</span>
                </button>
            </div>
            <div class="c-stap__body">
                <FormField :label="$tr('step2.purchaselocation')" :value="getCompanyInformation" />
                <FormField :label="$tr('step2.installdate')" :value="getInstallDate" />
                <FormField :label="$tr('step2.ordernumber')" :value="userData.ordernumber" />
            </div>
        </div>

        <div v-for="(promotionFields, index) in userData.promotionfieldvalues" v-bind:key="index">
            <!-- check the promotion id of the first field (all the fields should have the same promotion id)-->
            <div class="c c-stap" v-if="promotionFields.length > 0 && IsPromotionSelected(promotionFields[0].promotionId, promotionFields[0].typenumber)">
                <div class="c-stap__header">
                    <h3>{{ getPromotionInfoText(promotionFields[0]) }}</h3>
                    <button class="btn btn-link btn-hasicon" @click="$emit('editPromotionFieldsData')">
                        <i class="fal fa-edit"></i>
                        <span>{{ $tr('wizard.button.edit') }}</span>
                    </button>
                </div>
                <div class="c-stap__body">
                    <FormField v-for="field in promotionFields" :key="field.id" :label="$tr(field.label_name)" :value="displayFieldValue(field)" />
                </div>
            </div>
        </div>

        <div class="c c-stap">
            <div class="c-stap__header">
                <h3>
                    <template v-if="userData.articlelist.length === 1">
                        {{ $tr('wizard.step1.title.singular') }}
                    </template>
                    <template v-else>
                        {{ $tr('wizard.step1.title.plural') }}
                    </template>
                </h3>
                <button class="btn btn-link btn-hasicon" @click="$emit('editDevices')">
                    <i class="fal fa-edit"></i>
                    <span>{{ $tr('wizard.button.edit') }}</span>
                </button>
            </div>
            <div class="c-stap__body">
                <div class="c-device__overview">
                    <Device
                        v-for="(article, index) in userData.articlelist"
                        :index="index"
                        :brand="brand"
                        :article="article"
                        :userData="userData"
                        :key="article.productId"
                        :editable="false"
                        :v="v"
                    />
                </div>
            </div>
        </div>

        <div class="c c-stap">
            <h3 class="c-stap__header">{{ $tr('wizard.checkstep.terms.title') }}</h3>
            <div class="c-stap__body mt-1">
                <!-- <div class="form-check">
                <input class="custom-control-input" type="checkbox" id="customercommercialapproved" v-model="userData.customercommercialapproved" />
                <label class="custom-control-label" for="customercommercialapproved">{{ $tr('step4.acceptcommercial') }}</label>
            </div> -->
                <div class="form-check">
                    <input class="custom-control-input" type="checkbox" value id="terms" v-model="userData.terms" />
                    <label class="custom-control-label" for="terms">
                        <template v-if="brand == 'hisense' && lang === 'nl'">
                            {{ $tr('wizard.checkstep.terms.iacceptthe') }}
                            {{ $tr('wizard.checkstep.terms.termsandconditions') }}
                        </template>
                        <template v-else>
                            {{ $tr('wizard.checkstep.terms.iacceptthe') }}
                            <a :href="getLinkDisclaimer" target="_blank" :title="$tr('wizard.checkstep.terms.termsandconditions')">
                                {{ $tr('wizard.checkstep.terms.termsandconditions') }}
                            </a>
                        </template>
                    </label>
                    <template v-if="brand == 'hisense' && lang === 'nl'">
                        <ul class="c-arrowlist">
                            <li>
                                <span>
                                    <a href="https://www.atagbenelux.com/nl/garantievoorwaarden-tv/" target="_blank"
                                        >Bekijk hier de voorwaarden voor audio visuele apparaten</a
                                    >
                                </span>
                            </li>
                            <li>
                                <span><a :href="getLinkDisclaimer" target="_blank">Bekijk hier de voorwaarden voor overige apparaten</a></span>
                            </li>
                        </ul>
                    </template>
                    <span class="error field-validation-error" v-if="wizard.validationTerms && !userData.terms">{{ $tr('step4.pleaseacceptterms') }}</span>
                </div>
                <div class="form-check">
                    <input class="custom-control-input" type="checkbox" value id="newsletter" v-model="userData.newsletter" />
                    <label class="custom-control-label" for="newsletter">
                        {{ $tr('wizard.checkstep.terms.newsletter') }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import FormField from './components/forms/formfield.vue';
import Device from './components/device.vue';

export default {
    name: 'step-confirm',
    data() {
        return {
            linkDisclaimerNL: 'https://www.atagbenelux.com/wp-content/uploads/2019/10/atag-nl-service-garantievoorwaarden-aaeph.pdf',
            linkDisclaimerBE: 'https://www.atagbenelux.com/wp-content/uploads/2019/10/atag-be-service-garantievoorwaarden-01092019.pdf',
            linkDisclaimerFR: 'https://www.atagbenelux.com/wp-content/uploads/2019/10/atag-be-fr-service-garantievoorwaarden-01092019.pdf'
        };
    },
    components: {
        Device,
        FormField
    },
    computed: {
        getAddress() {
            let street = this.userData.street ? this.userData.street : '';
            let housenumber = this.userData.housenumber || '';
            let housenumberadd = this.userData.housenumberadd || '';
            let zipcode = this.userData.zipcode || '';
            let city = this.userData.city ? this.userData.city : '';

            if (this.userData.language === 'NL' && this.userData.country === 'NL') {
                // street and city are arrays for NL version.
                street = this.userData.street ? this.userData.street[0] : '';
                city = this.userData.city ? this.userData.city[0] : '';
            }

            if (street === '' || housenumber === '' || zipcode === '' || city === '') {
                return '';
            }

            let address = street + ' ' + housenumber + housenumberadd + '<br />' + zipcode + '<br />' + city;
            return address;
        },
        getCompanyInformation() {
            if (this.userData.otherCompanyData) {
                return this.userData.otherCompanyData;
            } else if (this.userData.company.name && this.userData.company.city) {
                if (this.userData.company.city === 'Webshop') {
                    return this.userData.company.city + ': ' + this.userData.company.name;
                }

                return this.userData.company.name + '<br />' + this.userData.company.city;
            }

            return '';
        },
        getSexe() {
            let sexe = this.$tr('step1.sexe.m');
            if (this.userData.sexe === 'V') {
                sexe = this.$tr('step1.sexe.v');
            }
            return sexe;
        },
        getInstallDate() {
            if (!this.userData.installationdate) {
                return '';
            }
            return moment(this.userData.installationdate).format('DD-MM-YYYY');
        },
        getLinkDisclaimer() {
            if (this.lang === 'be') return this.linkDisclaimerBE;
            if (this.lang === 'fr') return this.linkDisclaimerFR;
            return this.linkDisclaimerNL;
        }
    },
    methods: {
        displayFieldValue(field) {
            if (field.value === null || field.value === '') return '-';

            if (field.inputType === 'date') return moment(field.value).format('DD-MM-YYYY');
            else if (field.inputType === 'checkbox') return field.value ? this.$tr('ja') : this.$tr('nee');
            else if (field.inputType === 'upload' || field.inputType === 'uploadAI') return field.value.name;
            else return field.value;
        },
        getPromotionByField(promotionField) {
            return this.promotionDtos.find(p => p.id === promotionField.promotionId);
        },
        getPromotionInfoText(promotionField) {
            let text = this.$tr('step2.promotioninfo');
            if (!this.promotionFoundInUrl) {
                const promotion = this.getPromotionByField(promotionField);
                text += ' ';
                text += promotion.name;
                if (this.userData.articlelist.length > 1) {
                    text += ' (';
                    text += promotionField.typenumber;
                    text += ')';
                }
            }
            return text;
        }
    },
    props: {
        brand: {
            type: String,
            default: null,
            required: true
        },
        userData: {
            type: Object,
            default: null,
            required: true
        },
        promotionFoundInUrl: {
            type: Boolean,
            required: false
        },
        promotionDtos: {
            type: Array,
            required: false
        },
        v: {
            type: Object,
            default: null,
            required: true
        },
        wizard: {
            type: Object,
            default: null,
            required: true
        },
        lang: {
            type: String,
            required: true
        }
    }
};
</script>
