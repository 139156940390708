<template>
    <div class="main intro-pelgrim">
        <div class="c c-wizard__title text-center">
            <div class="container">
                <h1 v-html="getTitle"></h1>
                <h2 class="c-wizard__title--sub text-primary" v-if="getSubtitle != ''" v-html="getSubtitle"></h2>
            </div>
        </div>
        <div class="c c-intropage__content">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-3 align-self-start">
                        <div class="c c-intropage__text">
                            <h2>
                                <span v-html="$tr('intropage.header.blocks.title')"></span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-12 col-lg-9">
                        <div class="c c-intropage__text">
                            <ul class="c-usplist">
                                <li>
                                    <i class="fal fa-clock"></i>
                                    <span v-html="$tr('intropage.header.blocks.item1')"></span>
                                </li>
                                <li>
                                    <i class="fal fa-barcode-read"></i>
                                    <span v-html="$tr('intropage.header.blocks.item2')"></span>
                                </li>
                                <li>
                                    <i class="fal fa-clipboard-user"></i>
                                    <span v-html="$tr('intropage.header.blocks.item3')"></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="c c-imgblock">
                            <img src="/img/pelgrim/intro-pelgrim1.jpg" alt="etna 1" loading="lazy" class="img-fluid" style="border-top-right-radius: 60px;" />
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="c c-intropage__text">
                            <template>
                                <h3><span v-html="$tr('intropage.block1.title')"></span></h3>
                                <p>
                                    <span v-html="$tr('intropage.block1.subtext')"></span>
                                </p>
                                <h4><span v-html="$tr('intropage.block1.list.title')"></span></h4>
                                <ul class="c-arrowlist">
                                    <li><span v-html="$tr('intropage.block1.list.item1')"></span></li>
                                    <li><span v-html="$tr('intropage.block1.list.item2')"></span></li>
                                </ul>
                            </template>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="c c-intropage__text">
                            <template>
                                <h3><span v-html="$tr('intropage.block3.title')"></span></h3>
                                <p>
                                    <span v-html="$tr('intropage.block3.text1')"></span>
                                </p>
                                <p>
                                    <span v-html="$tr('intropage.block3.text2')"></span>
                                </p>
                            </template>
                            <p><span v-html="$tr('intropage.disclaimerlinks')"></span></p>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="c c-imgblock text-center">
                            <img
                                src="/img/pelgrim/intro-pelgrim2-fr.png"
                                alt="etna 2"
                                loading="lazy"
                                v-if="lang === 'fr'"
                                class="img-fluid"
                                style="border-top-left-radius: 60px; border: 1px solid #dce6e2;"
                            />
                            <img
                                src="/img/pelgrim/intro-pelgrim2-be.png"
                                alt="etna 2"
                                loading="lazy"
                                v-else-if="lang === 'be'"
                                class="img-fluid"
                                style="border-top-left-radius: 60px; border: 1px solid #dce6e2;"
                            />
                            <img
                                src="/img/pelgrim/intro-pelgrim2-nl.png"
                                alt="etna 2"
                                loading="lazy"
                                v-else
                                class="img-fluid"
                                style="border-top-left-radius: 60px; border: 1px solid #dce6e2;"
                            />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="c-imgblock">
                            <img src="/img/pelgrim/intro-pelgrim3.jpg" alt="etna 3" loading="lazy" class="img-fluid" style="border-top-right-radius: 60px;" />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="c c-intropage__text">
                            <p><span v-html="$tr('intropage.bottom.text')"></span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-stickybtn">
            <button @click="$emit('start')" class="btn btn-secondary">
                <span v-html="$tr('wizard.button.register')"></span>
                <i class="fal fa-arrow-right"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'intropage-pelgrim',
    data() {
        return {};
    },
    props: {
        lang: {
            type: String,
            required: true
        }
    },
    mounted() {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        let product = params.get('modelnummer');
        if (product) {
            this.$emit('start');
        }
    },
    computed: {
        getTitle() {
            return this.$tr('intropage.title');
        },
        getSubtitle() {
            return this.$tr('intropage.subtitle');
        }
    }
};
</script>
