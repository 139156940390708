<template>
    <div v-show="!userData.articleListOrDealerChanged" class="c-promotions">
        <div class="c c-promotion" v-for="(promotion, index) in promotionDtos" :key="index">
            <h3>{{ GetPromotionDescriptionText(promotion) }}</h3>
            <label :class="['c-promotion__toggle', { checked: promotion.selected }]">
                <input type="checkbox" v-model="promotion.selected" />
                <span class="h5">{{ $tr('promotion.reminder.jaikneemdeel') }}</span>
            </label>
            <div class="c-promotion__inner" v-if="promotion.selected">
                <StepPromotionFields
                    :userData="userData"
                    :brand="brand"
                    :v="v"
                    :promotions="promotionDtos"
                    :promotionIndex="index"
                    :promotionFoundInUrl="promotionFoundInUrl"
                />
            </div>
        </div>
    </div>
</template>

<script>
import StepPromotionFields from './step-promotionfields.vue';
export default {
    name: 'step-promotion-reminder',
    components: {
        StepPromotionFields
    },
    data() {
        return {};
    },
    props: {
        userData: {
            type: Object,
            default: null,
            required: true
        },
        promotionDtos: {
            type: Array,
            required: true
        },
        brand: {
            type: String,
            default: null,
            required: true
        },
        v: {
            type: Object,
            default: null,
            required: true
        },
        promotionFoundInUrl: {
            type: Boolean,
            required: false
        }
    },
    methods: {
        GetPromotionDescriptionText(promotion) {
            let text = '';
            if (promotion.typeNumbers.length === 0) {
                return '';
            }
            let placeholder = '';
            if (this.userData.articlelist.length > 1) {
                placeholder = promotion.typeNumbers[0];
            }

            if (this.PromotionIsFromDealer(promotion.id, this.userData.company.name, this.userData.company.city)) {
                text = this.$tr('promotion.reminder.promotiontextdealer', { typenummersPlaceholder: placeholder });
            } else {
                text = this.$tr('promotion.reminder.promotiontextsingle', { typenummersPlaceholder: placeholder });
            }

            text += " '";
            text += promotion.labelKey_IntroText;
            text += "'";

            return text;
        }
    }
};
</script>

<style lang="scss" scoped></style>
