<template>
    <div class="c c-stap c-result position-static" style="min-height: 300px;">
        <template>
            <p v-if="!hasNonUrlPromotions && getPromotionText != ''">
                <span v-html="getPromotionText"></span>
            </p>
            <div class="c-result__promotions" v-else-if="hasNonUrlPromotions">
                <template v-for="(promotion, index) in getDistinctPromotions">
                    <div v-bind:key="index" class="c-result__promotions--item" v-if="promotion.selected">
                        <h3 v-html="getPromotionTitleWithPromotion(promotion)"></h3>
                        <p v-html="getPromotionTextWithPromotion(promotion)"></p>
                    </div>
                </template>
                <h3 v-html="$tr('wizard.result.header.subtitle')"></h3>
            </div>
            <p>
                <span v-html="$tr('result.box.congratulations.text1', { yearAmount1: orderAmount > 3 ? 8 : 5, yearAmount2: orderAmount > 3 ? 6 : 3 })"></span>
            </p>
            <p><span v-html="$tr('result.box.congratulations.text2')"></span></p>
            <p><span v-html="$tr('result.box.congratulations.text3')"></span></p>
        </template>
        <template v-if="lang !== 'fr'">
            <h3><span v-html="$tr('result.box.opinion.title')"></span></h3>
            <p>
                <span v-html="$tr('result.box.opinion.text1')"></span>
                <a :href="getLinkReview" target="_blank"><span v-html="$tr('result.box.opinion.text2')"></span></a>.
            </p>
        </template>
        <LinkButtons :linkReview="getLinkReview" :linkWebsite="getLinkWebsite" />
        <FootNote :lang="lang" :link="getLinkDisclaimer" />
    </div>
</template>
<script>
import LinkButtons from './linkbuttons.vue';
import FootNote from './footnote.vue';
export default {
    name: 'resultpage-atag',
    data() {
        return {
            linkDisclaimerNL: 'https://www.atagbenelux.com/wp-content/uploads/2019/10/atag-nl-service-garantievoorwaarden-aaeph.pdf',
            linkDisclaimerBE: 'https://www.atagbenelux.com/wp-content/uploads/2019/10/atag-be-service-garantievoorwaarden-01092019.pdf',
            linkDisclaimerFR: 'https://www.atagbenelux.com/wp-content/uploads/2019/10/atag-be-fr-service-garantievoorwaarden-01092019.pdf',
            linkReviewNL: 'https://www.atag.nl/review',
            linkReviewBE: 'https://www.atag.be/review?lang=nl',
            linkReviewFR: 'https://www.atag.be/review?lang=fr',
            linkWebsiteNL: 'https://www.atag.nl',
            linkWebsiteBE: 'https://www.atag.be/?lang=nl',
            linkWebsiteFR: 'https://www.atag.be/?lang=fr'
        };
    },
    components: {
        FootNote,
        LinkButtons
    },
    props: {
        userData: {
            type: Object,
            default: null,
            required: true
        },
        lang: {
            type: String,
            required: true
        },
        promotions: {
            type: Array,
            required: false
        },
        promotionFoundInUrl: {
            type: Boolean,
            required: false
        }
    },
    computed: {
        orderAmount() {
            return this.userData.articlelist.length;
        },
        getLinkDisclaimer() {
            if (this.lang === 'be') return this.linkDisclaimerBE;
            if (this.lang === 'fr') return this.linkDisclaimerFR;
            return this.linkDisclaimerNL;
        },
        getLinkReview() {
            if (this.lang === 'be') return this.linkReviewBE;
            if (this.lang === 'fr') return this.linkReviewFR;
            return this.linkReviewNL;
        },
        getLinkWebsite() {
            if (this.lang === 'be') return this.linkWebsiteBE;
            if (this.lang === 'fr') return this.linkWebsiteFR;
            return this.linkWebsiteNL;
        },
        getPromotionText() {
            if (this.promotionFoundInUrl && this.promotions[0] !== null && this.promotions[0].labelKey_ThankYouText !== '')
                return this.$tr(this.promotions[0].labelKey_ThankYouText);
            else return '';
        },
        getDistinctPromotions() {
            let uniqueIds = [];
            let uniquePromotions = [];
            for (const index in this.promotions) {
                const promotion = this.promotions[index];
                if (!uniqueIds.includes(promotion.id)) {
                    uniqueIds.push(promotion.id);
                    uniquePromotions.push(promotion);
                }
            }
            return uniquePromotions;
        }
    },
    methods: {
        hasNonUrlPromotions() {
            if (this.promotionFoundInUrl) {
                return false;
            }
            let hasPromotion = this.promotions.find(p => p.selected === true);
            if (hasPromotion === undefined) return false;
            return true;
        },
        getPromotionTextWithPromotion(promotion) {
            if (promotion !== null && promotion.labelKey_ThankYouText !== '') return this.$tr(promotion.labelKey_ThankYouText);
            else return '';
        },
        getPromotionTitleWithPromotion(promotion) {
            if (promotion !== null && promotion.labelKey_ThankYouTitle !== '') return this.$tr(promotion.labelKey_ThankYouTitle);
            else return '';
        }
    }
};
</script>
