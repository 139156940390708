import { render, staticRenderFns } from "./intropage-pelgrim.vue?vue&type=template&id=5e165e7c"
import script from "./intropage-pelgrim.vue?vue&type=script&lang=js"
export * from "./intropage-pelgrim.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports