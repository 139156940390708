<template>
    <div class="c-wizard__btns">
        <div class="container">
            <div :class="`c-wizard__btns--inner d-flex ${alignbuttons}`">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'wizardbuttons-wrapper',
    data() {
        return {};
    },
    methods: {
    },
    props: {
        alignbuttons: {
            type: String,
            required: true
        }
    }
};
</script>
