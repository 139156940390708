function toReadableModelNumber(modelNumber) {
    let lastIndex = modelNumber.length;
    if (modelNumber.includes("/")) {
        lastIndex = modelNumber.lastIndexOf("/");
    }
    return modelNumber.substring(0, lastIndex);
}

function toReadableArticleNumber(articleNumber) {
    let res = '';
    for (let i = 0; i < articleNumber.length; i++) {
        if (articleNumber[i] === '0' && res.length === 0) {
            continue;
        }
        else {
            res += articleNumber[i];
        }
    }
    return res;

}


export default { toReadableModelNumber, toReadableArticleNumber };
