<template>
    <div class="c c-stap" :validator="v.form">
        <h3 class="c-stap__header">
            <span>{{ $tr('step1.personalinfo') }}</span>
            <button class="btn btn-secondary" @click="prefillform" v-if="isLocal()">Autofill form</button>
        </h3>
        <div class="c-stap__body">
            <div class="form-group row">
                <label for="inputState" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">{{ $tr('step1.salutation') }}</label>
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <select id="inputState" class="form-control" v-model="userData.sexe">
                        <option value="" selected>{{ $tr('step1.sexe.choose') }}</option>
                        <option value="M">{{ $tr('step1.sexe.m') }}</option>
                        <option value="V">{{ $tr('step1.sexe.v') }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="name" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label"
                    >{{ $tr('step1.lastname') }} <span class="required-field">*</span></label
                >
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <input
                        type="text"
                        :class="`form-control ${v.userData.name.$error ? 'input-validation-error' : ''}`"
                        id="name"
                        v-model="userData.name"
                        @input="v.userData.name.$touch()"
                    />
                    <div class="text-sm mt-2 text-red" v-if="v.userData.name.$error">
                        <div v-if="!v.userData.name.required">{{ $tr('step1.lastname') }}: {{ $tr('general.isrequired') }}</div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="street" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">{{ $tr('step1.street') }}</label>
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <input type="text" class="form-control" id="street" v-model="userData.street" />
                </div>
            </div>
            <div class="form-group row">
                <div class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">
                    <label for="housenumber">{{ $tr('step1.housenumber') }} <span class="required-field">*</span></label>
                    <span> &amp; </span>
                    <label for="suffix">{{ $tr('step1.housenumber.suffix') }}</label>
                </div>
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <div class="d-flex">
                        <input
                            type="text"
                            :class="`form-control form-medium ${v.userData.housenumber.$error ? 'input-validation-error' : ''}`"
                            id="housenumber"
                            v-model="userData.housenumber"
                            @input="v.userData.housenumber.$touch()"
                            style="margin-right: 20px;"
                        />
                        <input type="text" class="form-control form-medium" id="suffix" v-model="userData.housenumberadd" />
                    </div>
                    <div class="text-sm mt-2 text-red" v-if="v.userData.housenumber.$error">
                        <div v-if="!v.userData.housenumber.required">{{ $tr('step1.housenumber') }}: {{ $tr('general.isrequired') }}</div>
                        <div v-if="!v.userData.housenumber.numeric">{{ $tr('step1.housenumber') }}: {{ $tr('general.isinvalid') }}</div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="zipcode" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label"
                    >{{ $tr('step1.zipcode') }} <span class="required-field">*</span></label
                >
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <input
                        type="text"
                        :class="`form-control ${v.userData.zipcode.$error ? 'input-validation-error' : ''}`"
                        id="zipcode"
                        v-model="userData.zipcode"
                        v-on:change="getCities"
                        @input="v.userData.zipcode.$touch()"
                    />
                    <div class="text-sm mt-2 text-red " v-if="v.userData.zipcode.$error">
                        <div v-if="!v.userData.zipcode.required">{{ $tr('step1.zipcode') }}: {{ $tr('general.isrequired') }}</div>
                        <template v-else>
                            <div v-if="!v.userData.zipcode.maxLength">{{ $tr('step1.zipcode') }}: {{ $tr('general.maxlength') }}</div>
                            <div v-if="!v.userData.zipcode.alphaNum">{{ $tr('step1.zipcode') }}: {{ $tr('general.alphanum') }}</div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="city" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">{{ $tr('step1.city') }}</label>
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <select id="city" class="form-control" :disabled="cities == null" v-model="userData.city">
                        <option v-for="(city, index) in cities" :key="index">{{ city }}</option>
                    </select>
                    <template v-if="invalidAdress">
                        <div class="mt-2 p-2 adress-error">
                            <span v-html="$tr('ongeldigadres')"></span>
                        </div>
                    </template>
                </div>
            </div>
            <div class="form-group row">
                <label for="phone.mobile" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label hastooltip">
                    <span>{{ $tr('step1.phone') }} <span class="required-field">*</span></span>
                    <span class="customtooltip">
                        <span class="tooltip-item"></span>
                        <span class="tooltip-content clearfix"
                            ><small>{{ $tr('step1.phone.tooltip') }}</small></span
                        >
                    </span>
                </label>
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <input
                        type="text"
                        :class="`form-control ${v.userData.phonenumber.$error ? 'input-validation-error' : ''}`"
                        id="phone.mobile"
                        v-model="userData.phonenumber"
                        @input="v.userData.phonenumber.$touch()"
                    />
                    <div class="text-sm mt-2 text-red" v-if="v.userData.phonenumber.$error">
                        <div v-if="!v.userData.phonenumber.required">{{ $tr('step1.phone') }}: {{ $tr('general.isrequired') }}</div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="email" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label hastooltip">
                    <span>{{ $tr('step1.email') }} <span class="required-field">*</span></span>
                    <span class="customtooltip">
                        <span class="tooltip-item"></span>
                        <span class="tooltip-content clearfix"
                            ><small>{{ $tr('step1.email.tooltip') }}</small></span
                        >
                    </span>
                </label>
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <input
                        type="text"
                        :class="`form-control ${v.userData.emailaddress.$error ? 'input-validation-error' : ''}`"
                        id="email"
                        value="email@example.com"
                        v-model="userData.emailaddress"
                        @input="v.userData.emailaddress.$touch()"
                    />
                    <div class="text-sm mt-2 text-red" v-if="v.userData.emailaddress.$error">
                        <div v-if="!v.userData.emailaddress.required">{{ $tr('step1.email') }}: {{ $tr('general.isrequired') }}</div>
                        <div v-else-if="!v.userData.emailaddress.email">{{ $tr('step1.email') }}: {{ $tr('general.isinvalid') }}</div>
                    </div>
                </div>
            </div>
            <RequiredFieldExplanation />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import RequiredFieldExplanation from './components/forms/required-field-explanation.vue';

let parseString = require('xml2js').parseString;

export default {
    name: 'step-personalinfo-be',
    data() {
        return {
            invalidAdress: false,
            cities: null,
            name: this.userData.name,
            sexe: this.userData.sexe,
            zipcode: this.userData.zipcode,
            housenumber: this.userData.housenumber,
            busnumber: this.userData.busnumber,
            housenumberadd: this.userData.housenumberadd,
            street: this.userData.street,
            city: this.userData.city,
            phonenumber: this.userData.phonenumber,
            emailaddress: this.userData.emailaddress
        };
    },
    props: {
        userData: {
            type: Object,
            default: null,
            required: true
        },
        brand: {
            type: String,
            default: null,
            required: true
        },
        v: {
            type: Object,
            default: null,
            required: true
        },
        serviceUrl: String
    },
    components: {
        RequiredFieldExplanation
    },
    methods: {
        isLocal() {
            let localhost = window.location.hostname === 'localhost';
            return localhost;
        },
        prefillform() {
            this.userData.name = 'Anonymous';
            this.userData.sexe = 'V';
            this.userData.zipcode = '9450';
            this.userData.street = 'Atomveldstraat';
            this.userData.housenumber = '1';
            this.userData.housenumberadd = 'b';
            // this.userData.city = 'Denderhoutem';
            this.userData.phonenumber = '0315123123';
            this.userData.emailaddress = 'noreply@frontis.nl';
            this.userData.installationdate = new Date();
            // this.userData.company.name = 'Media Markt Alkmaar B.V.'; turned of for action-reminders
            // this.userData.company.city = 'ALKMAAR';
            this.userData.ordernumber = 'OR123456';
            this.getCities();
        },
        getCities() {
            let xmls =
                '<ATAGPostalCodeCheck Mode="production"><Client>ATAGSERV</Client><PostalCode>' + this.userData.zipcode + '</PostalCode></ATAGPostalCodeCheck>';
            const token = Buffer.from(`ATAGSERV:DB58783fC`, 'utf8').toString('base64');

            axios
                .post(this.serviceUrl, xmls, { headers: { 'Content-Type': 'text/xml', Authorization: `Basic ${token}` } })
                .then(res => {
                    var self = this;

                    parseString(res.data, function(err, result) {
                        if (result.ATAGPostalCodeCheck.Cities[0].City === undefined) {
                            self.invalidAdress = true;
                        } else {
                            self.invalidAdress = false;
                        }
                        if (result.ATAGPostalCodeCheck.Cities.length > 0) {
                            self.cities = result.ATAGPostalCodeCheck.Cities[0].City;
                        } else {
                            console.log(err);
                        }
                    });
                })
                .catch(err => {
                    console.log(err);
                    self.cities = null;
                });
        }
    }
};
</script>
