<template>
    <div :class="['c c-wizard__title text-center', { hasbadge: step === 3 }]">
        <h1 v-html="getTitle"></h1>
        <h2 class="c-wizard__title--sub text-primary" v-if="getSubtitle != ''" v-html="getSubtitle"></h2>
    </div>
</template>

<script>
export default {
    name: 'wizard-title',
    data() {
        return {};
    },
    props: {
        brand: {
            type: String,
            required: false
        },
        step: {
            type: Number,
            required: false,
            default: 1
        },
        title: {
            type: String,
            required: false
        },
        subtitle: {
            type: String,
            required: false
        },
        promotions: {
            type: Array,
            required: false
        },
        promotionFoundInUrl: {
            type: Boolean,
            required: false
        }
    },
    computed: {
        getTitle() {
            if (
                this.promotionFoundInUrl &&
                this.promotions[0] !== null &&
                this.promotions[0].labelKey_IntroTitle !== null &&
                this.$tr(this.promotions[0].labelKey_IntroTitle) !== '[' + this.promotions[0].labelKey_IntroTitle + ']'
            ) {
                return this.$tr(this.promotions[0].labelKey_IntroTitle);
            }

            if (this.title) {
                return this.title;
            }
            return this.$tr('step1.registeryour') + ' ' + this.getBrandname + ' ' + this.$tr('step1.equipment');
        },
        getBrandname() {
            const brand = this.brand.toUpperCase();
            if (brand === 'PELGRIM') {
                return 'Pelgrim';
            } else if (brand === 'HISENSE') {
                return 'Hisense';
            }

            return brand;
        },
        getSubtitle() {
            if (
                this.promotionFoundInUrl &&
                this.promotions[0] !== null &&
                this.promotions[0].labelKey_IntroText !== null &&
                this.$tr(this.promotions[0].labelKey_IntroText) !== '[' + this.promotions[0].labelKey_IntroText + ']'
            ) {
                return this.$tr(this.promotions[0].labelKey_IntroText);
            }

            let returnTitle = '';
            if (this.subtitle) {
                return this.subtitle;
            } else {
                returnTitle = this.$tr('wizard.header.subtitle');
            }
            if (returnTitle === '[wizard.header.subtitle]') {
                return '';
            } else {
                return returnTitle;
            }
        }
    }
};
</script>
