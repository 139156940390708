<template>
    <div class="c-balloons">
        <svg xmlns="http://www.w3.org/2000/svg" width="175.807" height="472.692" viewBox="0 0 175.807 472.692" class="c-balloons__balloon1">
            <g>
                <path
                    fill="none"
                    class="stroke-balloon"
                    stroke-width="2.00104"
                    d="M79.626 180.253s3.476 3.184-4.384 41.544-23.018 42.464-25.063 81.526 23.31 65.309 24.29 107.007c.878 37.444-20.365 56.192-24.945 59.813-.256.204-.532.397-.532.397"
                />
                <path
                    class="fill-balloon1"
                    d="M69.895 187.102a3.002 3.002 0 0 1-2.67-3.3l.266-2.52a3.009 3.009 0 0 1 .164-.713c-37.032-10.35-62.108-49.481-57.511-93.042 5.07-48.057 44.34-83.302 87.71-78.725s74.413 47.244 69.342 95.3c-4.542 43.043-36.512 75.808-74.294 78.891a3.002 3.002 0 0 1 .057.979l-.266 2.52a3.002 3.002 0 0 1-3.3 2.67Z"
                />
                <g fill="#fff" v-if="brand === 'etna'">
                    <path
                        d="m66.772 63.382-4.841 45.88 17.402 1.836c1.328-3.482 4.57-5.916 9.633-5.382 6.434.68 9.525 4.738 8.79 11.699-.701 6.644-4.177 11.29-11.033 10.566-5.906-.623-8.587-4.528-8.902-8.617l-17.617-1.86c-.766 13.357 8.24 24.012 25.538 25.837 17.086 1.803 27.691-7.742 29.405-23.984 1.314-12.446-3.773-25.887-20.543-27.656-6.117-.646-11.624 1.013-14.685 3.782l1.536-14.555 32.902 3.473 1.67-15.82Z"
                    />
                    <path
                        d="m75.225 54.688 2.893.306 1.253-11.875-2.893-.305-.49 4.652-4.5-.475.49-4.652-2.892-.305-1.253 11.874 2.893.306.516-4.889 4.5.475Zm7.849.829 2.892.305 1.253-11.874-2.892-.306Zm14.68-10.594a5.827 5.827 0 0 0-6.677 5.418 5.827 5.827 0 0 0 5.418 6.678 5.847 5.847 0 0 0 6.15-3.49l.283-2.687-6.292-.664-.225 2.128 3.67.388a2.86 2.86 0 0 1-3.096 1.794c-2.08-.22-3.197-1.638-2.965-3.835.218-2.063 1.592-3.252 3.453-3.056a2.501 2.501 0 0 1 2.207 1.463l3.197.337c-.37-2.502-2.28-4.175-5.122-4.474Zm16.342 13.867 2.892.306 1.253-11.875-2.892-.305-.49 4.652-4.5-.475.49-4.652-2.892-.305-1.253 11.874 2.893.306.515-4.889 4.5.475Z"
                    />
                </g>
            </g>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="126.806" height="302.38" viewBox="0 0 126.806 302.38" class="c-balloons__balloon2">
            <g>
                <path
                    fill="none"
                    class="stroke-balloon"
                    stroke-width="2.00104"
                    d="M57.019 128.427s1.776 2.232-6.554 24.418-17.781 23.203-22.698 46.36 7.75 41.243 4.387 66.26c-3.019 22.466-17.492 31.661-20.573 33.391-.173.098-.355.188-.355.188"
                />
                <path
                    class="fill-balloon2"
                    d="M51.296 129.624a2.924 2.924 0 0 1-1.99-4.568c-25.234-7.868-41.581-35.33-37.38-65.127C16.544 27.161 44.264 3.977 73.83 8.145s49.798 34.112 45.18 66.88c-4.153 29.457-26.974 51.17-53.027 52.227a2.924 2.924 0 0 1-3.113 4.007Z"
                />
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'balloons',
    data() {
        return {};
    },
    props: {
        brand: {
            type: String,
            required: true
        }
    }
};
</script>
