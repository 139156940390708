<template>
    <div class="c c-stap" :validator="v.form">
        <h3 class="c-stap__header">
            <span>{{ $tr('step1.personalinfo') }}</span>
            <button class="btn btn-secondary" @click="prefillform" v-if="isLocal()">Autofill form</button>
        </h3>
        <div class="c-stap__body">
            <div class="form-group row">
                <label for="inputState" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">{{ $tr('step1.salutation') }}</label>
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <select id="inputState" class="form-control" v-model="userData.sexe">
                        <option value="" selected>{{ $tr('step1.sexe.choose') }}</option>
                        <option value="M">{{ $tr('step1.sexe.m') }}</option>
                        <option value="V">{{ $tr('step1.sexe.v') }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="name" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">
                    {{ $tr('step1.lastname') }} <span class="required-field">*</span>
                </label>
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <input
                        type="text"
                        :class="`form-control ${v.userData.name.$error ? 'input-validation-error' : ''}`"
                        id="name"
                        v-model="userData.name"
                        name="family-name"
                        @input="v.userData.name.$touch()"
                    />
                    <div class="text-sm mt-2 text-red" v-if="v.userData.name.$error">
                        <div v-if="!v.userData.name.required">{{ $tr('step1.lastname') }}: {{ $tr('general.isrequired') }}</div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="zipcode" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">
                    {{ $tr('step1.zipcode') }} <span class="required-field">*</span>
                </label>
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <input
                        type="text"
                        :class="`form-control ${v.userData.zipcode.$error ? 'input-validation-error' : ''}`"
                        id="zipcode"
                        name="postal-code"
                        v-model="userData.zipcode"
                        @input="v.userData.zipcode.$touch()"
                        v-on:change="getCityAndStreet"
                    />
                    <div class="text-sm mt-2 text-red" v-if="v.userData.zipcode.$error">
                        <div v-if="!v.userData.zipcode.required">{{ $tr('step1.zipcode') }}: {{ $tr('general.isrequired') }}</div>
                        <template v-else>
                            <div v-if="!v.userData.zipcode.maxLength">{{ $tr('step1.zipcode') }}: {{ $tr('general.maxlength') }} 6</div>
                            <div v-if="!v.userData.zipcode.alphaNum">{{ $tr('step1.zipcode') }}: {{ $tr('general.alphanum') }}</div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">
                    <label for="housenumber">{{ $tr('step1.housenumber') }} <span class="required-field">*</span></label>
                    <span> &amp; </span>
                    <label for="suffix">{{ $tr('step1.housenumber.suffix') }}</label>
                </div>
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <div class="d-flex">
                        <input
                            type="text"
                            :class="`form-control form-medium ${v.userData.housenumber.$error ? 'input-validation-error' : ''}`"
                            id="housenumber"
                            v-model="userData.housenumber"
                            @input="v.userData.housenumber.$touch()"
                            v-on:change="getCityAndStreet"
                            style="margin-right: 20px;"
                        />
                        <input type="text" class="form-control form-medium" id="suffix" v-model="userData.housenumberadd" />
                    </div>
                    <template v-if="userData.street && userData.city && userData.street[0] !== '' && userData.city[0] !== ''">
                        <div class="mt-2">{{ userData.street[0] }} {{ userData.housenumber }}, {{ userData.city[0] }}</div>
                    </template>
                    <template v-if="invalidAdress">
                        <div class="mt-2 p-2 adress-error">
                            <span v-html="$tr('ongeldigadres')"></span>
                        </div>
                    </template>
                    <div class="text-sm mt-2 text-red" v-if="v.userData.housenumber.$error">
                        <div v-if="!v.userData.housenumber.required">{{ $tr('step1.housenumber') }}: {{ $tr('general.isrequired') }}</div>
                        <div v-if="!v.userData.housenumber.numeric">{{ $tr('step1.housenumber') }}: {{ $tr('general.isinvalid') }}</div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="phone.mobile" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label hastooltip">
                    <span
                        >{{ $tr('step1.phone') }}
                        <span class="required-field"
                            >*<br /><small class="text-muted">{{ $tr('general.num') }}</small></span
                        ></span
                    >
                    <span class="customtooltip">
                        <span class="tooltip-item"></span>
                        <span class="tooltip-content clearfix"
                            ><small>{{ $tr('step1.phone.tooltip') }}</small></span
                        >
                    </span>
                </label>
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <input
                        type="text"
                        :class="`form-control ${phonenumber.$error ? 'input-validation-error' : ''}`"
                        id="phone.mobile"
                        v-model="phonenumber"
                        @input="acceptPhoneNumber"
                    />
                    <div class="text-sm mt-2 text-red" v-if="v.userData.phonenumber.$error || v.userData.phonenumber.$error">
                        <div>{{ $tr('step1.phone') }}: {{ $tr('general.isrequired') }}</div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="email" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label hastooltip">
                    <span>{{ $tr('step1.email') }} <span class="required-field">*</span></span>
                    <span class="customtooltip">
                        <span class="tooltip-item"></span>
                        <span class="tooltip-content clearfix"
                            ><small>{{ $tr('step1.email.tooltip') }}</small></span
                        >
                    </span>
                </label>
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <input
                        type="text"
                        :class="`form-control ${v.userData.emailaddress.$error ? 'input-validation-error' : ''}`"
                        id="email"
                        value="email@example.com"
                        name="email"
                        v-model="userData.emailaddress"
                        @input="v.userData.emailaddress.$touch()"
                    />
                    <div class="text-sm mt-2 text-red" v-if="v.userData.emailaddress.$error">
                        <div v-if="!v.userData.emailaddress.required">{{ $tr('step1.email') }}: {{ $tr('general.isrequired') }}</div>
                        <div v-else-if="!v.userData.emailaddress.email">{{ $tr('step1.email') }}: {{ $tr('general.isinvalid') }}</div>
                    </div>
                </div>
            </div>
            <RequiredFieldExplanation />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import RequiredFieldExplanation from './components/forms/required-field-explanation.vue';

let parseString = require('xml2js').parseString;

export default {
    name: 'step-personalinfo-nl',
    data() {
        return {
            invalidAdress: false,
            phonenumber: '',
            isMobielNummer: false
        };
    },
    mounted() {
        this.phonenumber = this.userData.phonenumber || '';
    },
    props: {
        userData: {
            type: Object,
            default: null,
            required: true
        },
        brand: {
            type: String,
            default: null,
            required: true
        },
        v: {
            type: Object,
            default: null,
            required: true
        },
        serviceUrl: {
            type: String,
            default: null,
            required: true
        }
    },
    components: {
        RequiredFieldExplanation
    },
    methods: {
        getLanguageCode() {
            if (this.userData.language.toLowerCase() === 'fr') return 'fr';
            else if (this.userData.country.toLowerCase() === 'nl') return 'nl';
            else return 'be';
        },
        returnPartialPhoneNumber(matchResult, dashPosition) {
            let result = '';
            if (!matchResult) return result;

            // The 0th element is the total and not a group. We want to
            // return all the groups until an undefined or null is found.
            for (let i = 1; i < matchResult.length; i++) {
                if (matchResult[i] !== null && matchResult[i] !== undefined) {
                    result += matchResult[i];
                    if (i === dashPosition && matchResult[i + 1] !== null && matchResult[i + 1] !== undefined) result += '-';
                } else {
                    break;
                }
            }
            return result;
        },
        acceptPhoneNumber() {
            var vastNummer = /(0)([1-9])?([1-9])?([0-9])?([1-9])?([0-9])?([0-9])?([0-9])?([0-9])?([0-9])?/;
            var vastNummer2 = /(0)([1-9])?([0-9])?([1-9])?([0-9])?([0-9])?([0-9])?([0-9])?([0-9])?([0-9])?/;
            var mobielNummer = /(0)(6)?([1-9])?([0-9])?([0-9])?([0-9])?([0-9])?([0-9])?([0-9])?([0-9])?/;
            var vast1 = this.phonenumber.replace(/\D/g, '').match(vastNummer);
            var vast2 = this.phonenumber.replace(/\D/g, '').match(vastNummer2);
            var mobiel = this.phonenumber.replace(/\D/g, '').match(mobielNummer);
            var partialVast1 = this.returnPartialPhoneNumber(vast1, 4);
            var partialVast2 = this.returnPartialPhoneNumber(vast2, 3);
            var partialMobiel = this.returnPartialPhoneNumber(mobiel, 2);

            if (partialMobiel.replace('-', '').length >= partialVast1.replace('-', '').length) {
                this.phonenumber = partialMobiel;
                this.isMobielNummer = true;
            } else if (partialVast1.replace('-', '').length >= partialVast2.replace('-', '').length) {
                this.phonenumber = partialVast1;
                this.isMobielNummer = false;
            } else {
                this.phonenumber = partialVast2;
                this.isMobielNummer = false;
            }

            this.userData.phonenumber = this.phonenumber;
        },
        isLocal() {
            let localhost = window.location.hostname === 'localhost';
            return localhost;
        },
        prefillform() {
            this.userData.name = 'Anonymous';
            this.userData.sexe = 'V';
            this.userData.zipcode = '7051eb';
            this.userData.housenumber = '5';
            this.phonenumber = '0612345678';
            this.userData.emailaddress = 'noreply@frontis.nl';
            this.userData.installationdate = new Date();
            // this.userData.company.name = 'Media Markt Alkmaar B.V.'; turned of for action-reminders
            // this.userData.company.city = 'ALKMAAR';
            this.userData.ordernumber = 'OR123456';
            this.getCityAndStreet();
            this.acceptPhoneNumber();
        },
        getCityAndStreet() {
            let xmls =
                '<ATAGPostalCodeCheck Mode="production"><Client>ATAGSERV</Client><PostalCode>' +
                this.userData.zipcode +
                '</PostalCode><HouseNumber>' +
                this.userData.housenumber +
                '</HouseNumber><LanguageCode>NL</LanguageCode><Street></Street><City></City></ATAGPostalCodeCheck>';
            const token = Buffer.from(`ATAGSERV:DB58783fC`, 'utf8').toString('base64');
            var self = this;
            axios
                .post(this.serviceUrl, xmls, { headers: { 'Content-Type': 'text/xml', Authorization: `Basic ${token}` } })
                .then(res => {
                    parseString(res.data, function(err, result) {
                        if (result.ATAGPostalCodeCheck.Street[0] === '' && result.ATAGPostalCodeCheck.City[0] === '') {
                            self.invalidAdress = true;
                        } else {
                            self.invalidAdress = false;
                        }
                        if (result.ATAGPostalCodeCheck.City.length > 0) {
                            self.userData.city = result.ATAGPostalCodeCheck.City;
                            self.userData.street = result.ATAGPostalCodeCheck.Street;
                        } else {
                            console.log(err);
                        }
                    });
                })
                .catch(err => {
                    console.log(err);
                    self.userData.city = null;
                    self.userData.street = null;
                });
        }
    }
};
</script>

<style lang="scss" scoped></style>
