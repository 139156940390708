<template>
    <footer class="c-footer">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="c-footer__contact" v-if="getContactUrl()">
                        <a @click="toggleCookieOverview()" title="Cookies">Cookies</a>
                        <a :href="getContactUrl()" target="_blank" rel="nofollow" :title="$tr('footer.link.contact')">{{ $tr('footer.link.contact') }}</a>
                        <a :href="getPrivacyStatementUrl()" target="_blank" rel="nofollow" :title="$tr('footer.link.privacystatement')">
                            {{
                            $tr('footer.link.privacystatement')
                            }}
                        </a>
                    </div>
                    <div class="c-footer__copyright">
                        <span>Copyright &copy; {{ new Date().getFullYear() }}</span>
                        <span>{{ getBrandname() }} {{ $tr('footer.copyright.ispartofatag') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="showCookies" class="cookieoverview">
            <div class="container">
                <div class="row">
                    <div class="col-12" id="CookieOverviewContainer">
                        <h1>Cookies</h1>
                        <button @click="toggleCookieOverview()">X</button>
                    </div>
                </div>
            </div>
        </div>
</footer>
</template>

<script>
export default {
    name: 'wizard-footer',
    data() {
        return {
            showCookies: false,
            hasInsertedCookies: false,
        };
    },

    methods: {
        getBrandname() {
            const brand = this.brand.toUpperCase();
            if (brand === 'PELGRIM') {
                return 'Pelgrim';
            } else if (brand === 'HISENSE') {
                return 'Hisense';
            }

            return brand;
        },

        toggleCookieOverview() {
            document.body.classList.toggle('cookieoverview-active', !this.showCookies)

            this.showCookies = !this.showCookies

            if (this.showCookies && !this.hasInsertedCookies) {
                this.hasInsertedCookies = true;

                let cookieBotScript = document.createElement('script')
                cookieBotScript.type = 'text/javascript';
                cookieBotScript.src = 'https://consent.cookiebot.com/de4b41f3-a4ad-464a-a8c2-70fce92e25a3/cd.js'

                document.getElementById("CookieOverviewContainer").appendChild(cookieBotScript)
            }
        },

        getContactUrl() {
            const brand = this.brand.toLowerCase();
            const lang = this.lang;

            if (brand === 'asko') {
                if (lang === 'fr') return 'https://www.asko.be/fr/service-entretien/contact';
                if (lang === 'be') return 'https://www.asko.be/nl/service-support/contact';
                return 'https://www.asko.nl/service/contact';
            }

            if (brand === 'atag') {
                if (lang === 'fr') return 'https://atag.be/servicebe?lang=fr';
                if (lang === 'be') return 'https://atag.be/servicebe?lang=nl';
                return 'https://support.atag.nl/support/solutions/77000206918';
            }

            if (brand === 'etna') {
                if (lang === 'fr') return 'https://support.etna.be/nl/support/solutions/80000295139';
                if (lang === 'be') return 'https://support.etna.be/nl/support/solutions/80000295139';
                return 'https://support.etna.nl/support/solutions/77000206919';
            }

            if (brand === 'hisense') {
                if (lang === 'fr') return 'https://hisense.be/fr/service/';
                if (lang === 'be') return 'https://hisense.be/service/';
                return 'https://hisense.nl/service/';
            }

            if (brand === 'pelgrim') {
                if (lang === 'fr') return 'https://www.pelgrim.be/fr/';
                if (lang === 'be') return 'https://www.pelgrim.be/';
                return 'https://www.pelgrim.nl/';
            }

            return '';
        },
        getPrivacyStatementUrl() {
            return 'https://www.atagbenelux.com/wp-content/uploads/2021/07/ATAG-Benelux-Privacy-Policy-NL-FR-ENG-1-7-2021.pdf';
        }
    },
    props: {
        brand: {
            type: String,
            required: true
        },
        lang: {
            type: String,
            required: true
        }
    }
};
</script>
