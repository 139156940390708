<template>
    <div class="c-wizard__btns--inner d-flex">
        <a :href="linkReview" class="btn btn-primary" rel="nofollow" target="_blank" v-if="linkReview != ''">
            <span>{{ $tr('wizard.result.button.review') }}</span>
            <i class="fal fa-arrow-right"></i>
        </a>
        <a :href="linkWebsite" class="btn btn-outline-primary" rel="nofollow" target="_blank" v-if="linkWebsite != ''">
            <span>{{ $tr('wizard.result.button.website') }}</span>
            <i class="fal fa-arrow-right"></i>
        </a>
    </div>
</template>

<script>
export default {
    name: 'linkbuttons',
    data() {
        return {};
    },
    props: {
        linkReview: {
            type: String,
            default: '',
            required: false
        },
        linkWebsite: {
            type: String,
            default: '',
            required: false
        }
    }
};
</script>
