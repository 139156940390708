<template>
    <div class="c-footnote">
        <template>* <a :href="link" target="_blank" rel="noopener"><span v-html="$tr('result.footnote')"></span></a>.</template>
        
    </div>
</template>

<script>
export default {
    name: 'footnote',
    data() {
        return {};
    },
    props: {
        lang: {
            type: String,
            required: true
        },
        link: {
            type: String,
            required: true
        }
    }
};
</script>
