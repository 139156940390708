<template>
    <div class="site" v-if="loaded">
        <header class="c-header">
            <a :href="url" class="logo" v-if="wizard.currentStep === 4"><img :src="`/img/${brand}/logo.svg`"/></a>
            <div class="logo" v-else><img :src="`/img/${brand}/logo.svg`" /></div>
        </header>
        <SetLanguage v-if="!hasCulture" @setCulture="setCulture" />
        <template v-else-if="wizard.currentStep === 0 && promotionLoading === false">
            <IntroPageAsko v-if="brand === 'asko'" :lang="getLanguageCode()" @start="startWizard(true)" />
            <IntroPageAtag v-else-if="brand === 'atag'" :lang="getLanguageCode()" @start="startWizard(true)" />
            <IntroPageEtna v-else-if="brand === 'etna'" :lang="getLanguageCode()" @start="startWizard(true)" />
            <IntroPageHisense v-else-if="brand === 'hisense'" :lang="getLanguageCode()" @start="startWizard(true)" />
            <IntroPagePelgrim v-else-if="brand === 'pelgrim'" :lang="getLanguageCode()" @start="startWizard(true)" />
        </template>
        <template v-else>
            <div class="main">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <!-- DEVICES (& ACTIONGIFT) -->
                            <template v-if="wizard.currentStep === 1">
                                <WizardTitle :brand="brand" :step="wizard.currentStep" :promotions="promotionDtos" :promotionFoundInUrl="promotionFoundInUrl" />
                                <WizardSteps :firstStep="true" />
                                <StepDevices ref="stepDevices" :userData="userData" :brand="brand" :v="$v" />
                                <StepAddDevices
                                    :userData="userData"
                                    :brand="brand"
                                    :promotions="promotionDtos"
                                    :promotionFoundInUrl="promotionFoundInUrl"
                                    :show-add-device="wizard.showAddDevice"
                                    :v="$v"
                                    @hideAddDevice="hideAddDeviceButton"
                                />
                                <!-- <StepActionGift v-if="country.toLowerCase() == 'be'" :userData="userData" :brand="brand" :v="$v" /> -->
                            </template>

                            <!-- PERSONAL INFO & PURCHASE LOCATION (& PROMOTION) -->
                            <template v-else-if="wizard.currentStep === 2">
                                <WizardTitle :brand="brand" :step="wizard.currentStep" :promotions="promotionDtos" :promotionFoundInUrl="promotionFoundInUrl" />
                                <WizardSteps :firstStep="false" />
                                <StepPersonalInfoNL v-if="country.toLowerCase() == 'nl'" :userData="userData" :brand="brand" :v="$v" :serviceUrl="serviceUrl" />
                                <StepPersonalInfoBE v-if="country.toLowerCase() == 'be'" :userData="userData" :brand="brand" :v="$v" :serviceUrl="serviceUrl" />
                                <StepPurchaseLocation
                                    ref="stepPurchaseLocation"
                                    :userData="userData"
                                    :brand="brand"
                                    :v="$v"
                                    :promotionFoundInUrl="promotionFoundInUrl"
                                    :promotionUrl="promotion"
                                    @setPromotionDtosWithTypenumberAndDealer="setPromotionDtosWithTypenumberAndDealer"
                                />

                                <StepPromotionReminder
                                    v-if="!promotionFoundInUrl"
                                    :userData="userData"
                                    :promotionDtos="promotionDtos"
                                    :brand="brand"
                                    :v="$v"
                                    :promotionFoundInUrl="promotionFoundInUrl"
                                ></StepPromotionReminder>

                                <StepPromotionFields
                                    v-if="promotionDtos.length !== 0 && promotionFoundInUrl"
                                    :userData="userData"
                                    :brand="brand"
                                    :v="$v"
                                    :promotions="promotionDtos"
                                    :promotionIndex="0"
                                    :promotionFoundInUrl="promotionFoundInUrl"
                                />
                            </template>

                            <!-- CHECK & CONFIRM -->
                            <template v-else-if="wizard.currentStep === 3">
                                <WizardTitle :brand="brand" :step="wizard.currentStep" :promotions="promotionDtos" :promotionFoundInUrl="promotionFoundInUrl" />
                                <StepConfirm
                                    :userData="userData"
                                    :promotionFoundInUrl="promotionFoundInUrl"
                                    :promotionDtos="promotionDtos"
                                    :wizard="wizard"
                                    :brand="brand"
                                    :v="$v"
                                    :lang="getLanguageCode()"
                                    @editDevices="goToStep(1, false, false)"
                                    @editPersonalData="goToStep(2, false, false)"
                                    @editPurchaseData="goToPurchaseStep(2)"
                                    @editPromotionFieldsData="goToPromotionFieldsStep(2)"
                                />
                            </template>

                            <!-- THANKS -->
                            <template v-else-if="wizard.currentStep === 4">
                                <ResultPageTitle :brand="brand" :promotions="promotionDtos" :promotionFoundInUrl="promotionFoundInUrl" />
                                <ResultPageAsko
                                    v-if="brand === 'asko'"
                                    :userData="userData"
                                    :lang="getLanguageCode()"
                                    :promotions="promotionDtos"
                                    :promotionFoundInUrl="promotionFoundInUrl"
                                />
                                <ResultPageAtag
                                    v-else-if="brand === 'atag'"
                                    :userData="userData"
                                    :lang="getLanguageCode()"
                                    :promotions="promotionDtos"
                                    :promotionFoundInUrl="promotionFoundInUrl"
                                />
                                <ResultPageEtna
                                    v-else-if="brand === 'etna'"
                                    :userData="userData"
                                    :lang="getLanguageCode()"
                                    :promotions="promotionDtos"
                                    :promotionFoundInUrl="promotionFoundInUrl"
                                />
                                <ResultPageHisense
                                    v-else-if="brand === 'hisense'"
                                    :userData="userData"
                                    :lang="getLanguageCode()"
                                    :promotions="promotionDtos"
                                    :promotionFoundInUrl="promotionFoundInUrl"
                                />
                                <ResultPagePelgrim
                                    v-else-if="brand === 'pelgrim'"
                                    :userData="userData"
                                    :lang="getLanguageCode()"
                                    :promotions="promotionDtos"
                                    :promotionFoundInUrl="promotionFoundInUrl"
                                />
                            </template>

                            <!-- ERROR -->
                            <template v-else>
                                <WizardTitle title="Oeps!" subtitle="Er ging iets mis." :brand="brand" :step="wizard.currentStep" />
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <!-- wizard buttons -->
            <template v-if="wizard.currentStep === 1">
                <WizardButtonsWrapper alignbuttons="justify-content-end" v-if="userData.articlelist.length > 0">
                    <button class="btn btn-outline-primary" @click="wizard.showAddDevice = true" v-if="!wizard.showAddDevice">
                        <i class="fal fa-plus"></i>
                        <span v-html="$tr('wizard.button.addextradevice')"></span>
                    </button>
                    <button class="btn btn-danger" disabled v-if="$v.userData.articlelist.$error">
                        <span v-html="$tr('general.formcontainserrors')"></span>
                        <i class="fal fa-times"></i>
                    </button>
                    <button class="btn btn-next" v-else @click="goToStep(2, true, true)">
                        <span v-html="$tr('wizard.button.fillininformation')"></span>
                        <i class="fal fa-arrow-right"></i>
                    </button>
                </WizardButtonsWrapper>
            </template>
            <WizardButtonsWrapper alignbuttons="justify-content-between" v-else-if="wizard.currentStep === 2">
                <button class="btn btn-outline-primary" @click="goToStep(1, false, false)">
                    <i class="fal fa-arrow-left"></i>
                    <span v-html="$tr('wizard.button.editdevices')"></span>
                </button>
                <button class="btn btn-danger" disabled v-if="$v.$error">
                    <span v-html="$tr('general.formcontainserrors')"></span>
                    <i class="fal fa-times"></i>
                </button>
                <button class="btn btn-next" @click="goToStep(3, true, true)" v-else>
                    <span v-html="$tr('wizard.button.checkinformation')"></span>
                    <i class="fal fa-arrow-right"></i>
                </button>
            </WizardButtonsWrapper>
            <WizardButtonsWrapper alignbuttons="justify-content-between" v-else-if="wizard.currentStep === 3">
                <button class="btn btn-outline-primary" @click="goToStep(1, false, false)">
                    <i class="fal fa-arrow-left"></i>
                    <span v-html="$tr('wizard.button.editdevices')"></span>
                </button>
                <button class="btn btn-danger" disabled v-if="wizard.validationTerms && !userData.terms">
                    <span v-html="$tr('general.formacceptterms')"></span>
                    <i class="fal fa-times"></i>
                </button>
                <button class="btn btn-next" @click="sendForm()" :disabled="isSubmitting">
                    <span v-html="$tr('wizard.button.sendregistration')"></span>
                    <i class="fal fa-arrow-right"></i>
                </button>
            </WizardButtonsWrapper>
            <template v-else-if="wizard.currentStep === 4"> </template>
            <WizardButtonsWrapper alignbuttons="justify-content-center" v-else>
                <button class="btn btn-outline-primary" @click="goToStep(1, false, false)">
                    <i class="fal fa-arrow-left"></i>
                    <span v-html="$tr('wizard.button.restart')"></span>
                </button>
            </WizardButtonsWrapper>
        </template>
        <WizardFooter :brand="brand" :lang="getLanguageCode()" />
    </div>
</template>

<script>
import axios from 'axios';
import StepPersonalInfoNL from './step-personalinfo-nl.vue';
import StepPersonalInfoBE from './step-personalinfo-be.vue';
import StepPurchaseLocation from './step-purchaselocation.vue';
import StepPromotionFields from './step-promotionfields.vue';
import StepPromotionReminder from './step-promotion-reminder.vue';
import StepDevices from './step-devices.vue';
import StepAddDevices from './step-add-devices.vue';
// import StepActionGift from './step-actiongift.vue';
import StepConfirm from './step-confirm.vue';
import SetLanguage from './components/set-language.vue';
import WizardFooter from './components/wizard-footer.vue';
import WizardSteps from './components/wizard-steps.vue';
import WizardButtonsWrapper from './components/wizard-buttons-wrapper.vue';
import WizardTitle from './components/wizard-title.vue';
import ResultPageAsko from './components/resultpage/resultpage-asko.vue';
import ResultPageAtag from './components/resultpage/resultpage-atag.vue';
import ResultPageEtna from './components/resultpage/resultpage-etna.vue';
import ResultPageHisense from './components/resultpage/resultpage-hisense.vue';
import ResultPagePelgrim from './components/resultpage/resultpage-pelgrim.vue';
import ResultPageTitle from './components/resultpage/resultpage-title.vue';
import IntroPageAsko from './components/intropage/intropage-asko.vue';
import IntroPageAtag from './components/intropage/intropage-atag.vue';
import IntroPageEtna from './components/intropage/intropage-etna.vue';
import IntroPageHisense from './components/intropage/intropage-hisense.vue';
import IntroPagePelgrim from './components/intropage/intropage-pelgrim.vue';
import moment from 'moment';
import { required, email, numeric, requiredIf, alphaNum } from 'vuelidate/lib/validators';
import articleHelper from '../../util/articleHelper';
export default {
    name: 'garantiekaart',
    data() {
        return {
            isSubmitting: false,
            loaded: false,
            loading: false,
            serviceUrl: '',
            wizard: {
                currentStep: 0,
                showAddDevice: true,
                validationTerms: false
            },
            userData: {
                language: this.language.toUpperCase(),
                country: this.country.toUpperCase(),
                date: moment().format('DD-MM-YYYY'),
                sexe: '',
                name: null,
                zipcode: null,
                housenumber: null,
                housenumberadd: '',
                busnumber: null,
                street: null,
                city: null,
                phonenumber: null,
                emailaddress: null,
                company: {
                    name: null,
                    city: null
                },
                otherCompanyData: null,
                installationdate: null,
                ordernumber: null,
                articlelist: [],
                articleListOrDealerChanged: false,
                customercommercialapproved: null,
                surveyapproved: null,
                file: null,
                cadeauartikel: null,
                promotionfieldvalues: [],
                terms: false
            },
            promotionDtos: [],
            promotionLoading: false,
            promotionFoundInUrl: false
        };
    },
    watch: {
        'userData.articlelist': function() {
            if (this.userData.articlelist.length === 0) {
                this.wizard.showAddDevice = true;
            }
        }
    },
    props: {
        language: String,
        brand: String,
        country: String,
        promotion: String,
        url: String
    },
    components: {
        IntroPageAsko,
        IntroPageAtag,
        IntroPageEtna,
        IntroPageHisense,
        IntroPagePelgrim,
        ResultPageAsko,
        ResultPageAtag,
        ResultPageEtna,
        ResultPageHisense,
        ResultPagePelgrim,
        ResultPageTitle,
        SetLanguage,
        StepPersonalInfoNL,
        StepPersonalInfoBE,
        StepPurchaseLocation,
        StepPromotionFields,
        StepPromotionReminder,
        StepAddDevices,
        StepDevices,
        // StepActionGift,
        StepConfirm,
        WizardFooter,
        WizardSteps,
        WizardTitle,
        WizardButtonsWrapper
    },
    computed: {
        hasCulture() {
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            let language = params.get('language');
            let country = params.get('country');

            if (language && country) {
                if ((language === 'nl' || language === 'fr') && (country === 'nl' || country === 'be')) {
                    return true;
                }
            }

            return false;
        },
        typenumbers() {
            let typenumbers = '';

            for (const index in this.userData.articlelist) {
                const article = this.userData.articlelist[index];
                typenumbers += articleHelper.toReadableModelNumber(article.articlenumber) + ',';
            }
            // remove the last comma
            return typenumbers.substring(0, typenumbers.length - 1);
        }
    },
    methods: {
        hideAddDeviceButton() {
            this.wizard.showAddDevice = false;
        },
        goToStep(pag, validate, pushDatalayer) {
            if (validate) {
                if (this.wizard.currentStep === 1) {
                    this.$v.userData.articlelist.$touch();
                    if (!this.$v.userData.articlelist.$error) {
                        this.navigateToPage(pag, pushDatalayer);
                    }
                } else {
                    this.$v.$touch();
                    if (!this.$v.$error) {
                        this.navigateToPage(pag, pushDatalayer);
                    }
                }
            } else {
                this.navigateToPage(pag, pushDatalayer);
            }
        },
        navigateToPage(pag, pushDatalayer) {
            this.wizard.currentStep = pag;
            this.scrollToTop();
            if (pushDatalayer) this.handleDataLayer('productregistratie_stap_' + pag, 'registratie_stapnummer', pag);
        },
        goToPurchaseStep(pag, event) {
            this.wizard.currentStep = pag;

            setTimeout(function() {
                document.getElementById('purchaseLocation').scrollIntoView({
                    behavior: 'smooth'
                });
            }, 25);
        },
        goToPromotionFieldsStep(pag, event) {
            this.wizard.currentStep = pag;

            setTimeout(function() {
                document.getElementById('promotionFields').scrollIntoView({
                    behavior: 'smooth'
                });
            }, 25);
        },
        startWizard(pushDatalayer) {
            document.body.classList.remove('intropage');
            this.goToStep(1, false, pushDatalayer);
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getLanguageCode() {
            if (this.userData.language.toLowerCase() === 'fr') return 'fr';
            else if (this.userData.country.toLowerCase() === 'nl') return 'nl';
            else return 'be';
        },
        handleDataLayer(eventName, eventType, eventDataValue) {
            /* eslint-disable */
            if (typeof Cookiebot !== 'undefined' && Cookiebot.consent.marketing) {
                window.dataLayer = window.dataLayer || [];

                window.dataLayer.push({
                    event: `${eventName}`,
                    [eventType]: `${eventDataValue}`
                });
            }
            /* eslint-enable */
        },
        sendForm() {
            this.$v.$touch();
            if (this.$v.$error || this.userData.articlelist.length === 0) {
                return;
            }

            if (!this.userData.terms) {
                this.wizard.validationTerms = true;
                return;
            }

            this.isSubmitting = true; // Begin het laden

            let data = this.userData;
            let formData = new FormData();
            formData.append('Language', data.language);
            formData.append('Country', data.country);
            formData.append('Date', data.date);
            formData.append('BrandCode', this.brand);
            formData.append('Sexe', data.sexe);
            formData.append('Name', data.name);
            formData.append('Street', data.street);
            formData.append('HouseNumber', data.housenumber);
            formData.append('HouseNumberAdd', data.housenumberadd);
            formData.append('PostalCode', data.zipcode);
            formData.append('City', data.city);
            formData.append('PhoneNumber', data.phonenumber);
            formData.append('EmailAddress', data.emailaddress);
            formData.append('KitchenRetailerName', data.company.name);
            formData.append('KitchenRetailerCity', data.company.city);
            formData.append('Installationdate', moment(data.installationdate).format('DD-MM-YYYY'));
            formData.append('OrderNumber', data.ordernumber);
            formData.append('CustomerCommercialApproved', data.customercommercialapproved);
            formData.append('SurveyApproved', 'true'); // always send 'true'
            for (let i = 0; i < data.articlelist.length; i++) {
                formData.append('ArticleList[' + i + '].ArticleNumber', data.articlelist[i].articlenumber);
                formData.append('ArticleList[' + i + '].SerialNumber', data.articlelist[i].serialnumber);
            }
            formData.append('CadeauArtikel', data.cadeauartikel);
            formData.append('Url', window.location.href);
            formData.append('Invoice64', this.userData.file);
            formData.append('SubscribedToNewsletter', this.userData.newsletter);

            if (this.isLocal()) {
                // LOKAAL DIRECT NAAR DE BEDANKTPAGINA ZONDER MAIL TE STUREN + DATALAYER
                this.handleDataLayer('productregistratie_conversie', 'registratie_email', data.emailaddress);
                this.toResultPage();
            } else {
                axios
                    .post(`/api/garantie/PostForm`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        // send to datalayer
                        this.handleDataLayer('productregistratie_conversie', 'registratie_email', data.emailaddress);

                        // get promotions to send
                        let promotionsToSend = [];
                        for (const index in this.promotionDtos) {
                            const promotion = this.promotionDtos[index];
                            if (this.promotionFoundInUrl || promotion.selected) {
                                promotionsToSend.push(promotion);
                            }
                        }

                        if (promotionsToSend.length !== 0) {
                            for (const secondIndex in promotionsToSend) {
                                const promotion = promotionsToSend[secondIndex];

                                let isLastPromotion = false;
                                if (promotionsToSend.length === +secondIndex + 1) {
                                    isLastPromotion = true;
                                }
                                this.sendPromotion(response.data.id, promotion, isLastPromotion);
                            }
                        } else {
                            this.isSubmitting = false; // Stop het laden
                            this.toResultPage();
                        }
                    })
                    .catch(e => {
                        this.isSubmitting = false; // Stop het laden
                        console.error(e);
                    });
            }
        },
        sendPromotion(registrationId, promotionDto, isLast = false) {
            // send promotion to Core API
            if (promotionDto !== null) {
                // get promotionfields for promotion
                let promotionFields = this.getPromotionFieldsForPromotion(promotionDto);
                // when no fields are found we still want to send the promotion
                if (promotionFields === null) {
                    promotionFields = [];
                }

                let promotionFormData = new FormData();

                promotionFormData.append('PromotionId', promotionDto.id);
                promotionFormData.append('RegistrationId', registrationId);

                for (let i = 0; i < promotionFields.length; i++) {
                    let fieldValue = promotionFields[i].value;

                    if (promotionFields[i].inputType === 'date') {
                        // set date in right format
                        fieldValue = moment(fieldValue).format('YYYY-MM-DD');
                    } else if (promotionFields[i].inputType === 'upload' || promotionFields[i].inputType === 'uploadAI') {
                        // set file in fieldvalue object
                        promotionFormData.append('PromotionFieldValues[' + i + '].File', fieldValue);

                        // set filename as field-value
                        fieldValue = fieldValue.name;
                    }

                    promotionFormData.append('PromotionFieldValues[' + i + '].FieldId', promotionFields[i].id);
                    promotionFormData.append('PromotionFieldValues[' + i + '].Value', fieldValue);
                }

                promotionFormData.append('Sexe', this.userData.sexe);
                promotionFormData.append('Name', this.userData.name);
                promotionFormData.append('PostalCode', this.userData.zipcode);
                promotionFormData.append('HouseNumber', this.userData.housenumber);
                promotionFormData.append('HouseNumberAdd', this.userData.housenumberadd);
                promotionFormData.append('Street', this.userData.street);
                promotionFormData.append('City', this.userData.city);
                promotionFormData.append('PhoneNumber', this.userData.phonenumber);
                promotionFormData.append('EmailAddress', this.userData.emailaddress);

                let articleArray = [];
                if (this.promotionFoundInUrl) {
                    articleArray = this.userData.articlelist;
                } else {
                    for (const index in this.userData.articlelist) {
                        let article = this.userData.articlelist[index];
                        let articleTypenumber = articleHelper.toReadableModelNumber(article.articlenumber);
                        if (promotionDto.typeNumbers.includes(articleTypenumber)) {
                            articleArray.push(article);
                        }
                    }
                }
                for (let i = 0; i < articleArray.length; i++) {
                    promotionFormData.append('ArticleList[' + i + '].ArticleNumber', articleArray[i].articlenumber);
                    promotionFormData.append('ArticleList[' + i + '].SerialNumber', articleArray[i].serialnumber);
                    promotionFormData.append('ArticleList[' + i + '].SapMaterial', articleHelper.toReadableArticleNumber(articleArray[i].batchNumber));
                }

                axios
                    .post(`/api/promotions/PostForm`, promotionFormData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(() => {
                        if (isLast) {
                            this.toResultPage();
                            this.isSubmitting = false; // Stop het laden
                        }
                    })
                    .catch(e => {
                        console.error(e);
                        this.isSubmitting = false; // Stop het laden
                    });
            } else {
                this.isSubmitting = false; // Stop het laden
            }
        },
        toResultPage() {
            this.goToStep(4, false, false);
        },
        isLocal() {
            return false;
            // let localhost = window.location.hostname === 'localhost';
            // return localhost;
        },
        setCulture(country, language) {
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            params.set('country', country);
            params.set('language', language);
            window.location.href = window.location.origin + '?' + params.toString();
        },
        checkForResultPageRedirect() {
            // redirect backdoor to resultpage
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            let resultPage = params.get('resultpage');
            if (resultPage === '') {
                this.startWizard(false);
                this.toResultPage();
            }
            let selectPage = params.get('select');
            if (selectPage === '') {
                this.startWizard(false);
            }
        },
        getPromotionFieldsForPromotion(promotionDto) {
            for (const index in this.userData.promotionfieldvalues) {
                const fieldList = this.userData.promotionfieldvalues[index];
                if (fieldList.length === 0) {
                    continue;
                }
                if (this.promotionFoundInUrl) {
                    if (fieldList[0].promotionId === promotionDto.id) {
                        return fieldList;
                    }
                } else {
                    if (fieldList[0].promotionId === promotionDto.id && fieldList[0].typenumber === promotionDto.typeNumbers[0]) {
                        return fieldList;
                    }
                }
            }
            return null;
        },
        getArticleTypeNumbersList() {
            let typenumbers = [];

            for (const index in this.userData.articlelist) {
                const article = this.userData.articlelist[index];
                typenumbers.push(articleHelper.toReadableModelNumber(article.articlenumber));
            }
            return typenumbers;
        },
        getTypeNumbersByPromotion(promotion) {
            let articleTypeNumbers = this.getArticleTypeNumbersList();
            let matchedTypeNumbers = [];
            if (promotion.typeNumbers.length === 0) {
                return articleTypeNumbers;
            } else {
                for (const index in promotion.typeNumbers) {
                    const promotionTypenumber = promotion.typeNumbers[index];
                    if (articleTypeNumbers.includes(promotionTypenumber.typeNumber)) {
                        matchedTypeNumbers.push(promotionTypenumber.typeNumber);
                    }
                }
                return matchedTypeNumbers;
            }
        },
        splitPromotionsByArtilce(rawPromotions) {
            let newPromotions = [];
            for (const i in rawPromotions) {
                const rawPromotion = rawPromotions[i];
                rawPromotion.typeNumbers = this.getTypeNumbersByPromotion(rawPromotion);
                if (rawPromotion.typeNumbers.length === 0) {
                    let articleTypeNumbers = this.getArticleTypeNumbersList();
                    for (const j in articleTypeNumbers) {
                        let clonedPromotion = JSON.parse(JSON.stringify(rawPromotion));
                        clonedPromotion.typeNumbers = [];
                        clonedPromotion.typeNumbers[0] = articleTypeNumbers[j];
                        newPromotions.push(clonedPromotion);
                    }
                } else if (rawPromotion.typeNumbers.length > 1) {
                    for (const p in rawPromotion.typeNumbers) {
                        let clonedPromotion = JSON.parse(JSON.stringify(rawPromotion));
                        clonedPromotion.typeNumbers = [];
                        clonedPromotion.typeNumbers[0] = rawPromotion.typeNumbers[p];
                        newPromotions.push(clonedPromotion);
                    }
                } else if (rawPromotion.typeNumbers.length === 1) {
                    newPromotions.push(rawPromotion);
                }
            }
            return newPromotions;
        },
        setPromotionDtosWithTypenumberAndDealer() {
            if (this.userData.articleListOrDealerChanged) {
                this.promotionDtos = [];
                this.userData.promotionfieldvalues = [];

                let url = '/api/promotions/GetPromotionsByTypenumberAndDealer';
                url += `?typenumbers=` + this.typenumbers;
                url += `&accountName=` + this.userData.company.name ?? '';
                url += `&city=` + this.userData.company.city ?? '';
                url += `&brandCode=` + this.brand;
                url += `&country=` + this.country;

                axios
                    .get(url)
                    .then(response => {
                        if (response.status === 200 && response.data !== null) {
                            this.promotionDtos = this.splitPromotionsByArtilce(response.data);
                            this.promotionDtos.forEach(promotion => {
                                this.$set(promotion, 'selected', false);
                                let promotionFields = [];

                                for (const index in promotion.fields) {
                                    const field = promotion.fields[index];
                                    promotionFields.push({
                                        id: field.id,
                                        promotionId: promotion.id,
                                        typenumber: promotion.typeNumbers[0],
                                        inputType: field.inputType,
                                        mandatory: field.mandatory,
                                        label_name: field.labelKey_FieldName,
                                        label_info: field.labelKey_InfoButton,
                                        value: field.inputType === 'checkbox' ? false : ''
                                    });
                                }
                                this.userData.promotionfieldvalues.push(promotionFields);
                            });
                        }
                    })
                    .catch(e => {})
                    .finally(() => {
                        this.userData.articleListOrDealerChanged = false;
                    });
            }
        }
    },
    validations: {
        userData: {
            name: {
                required
            },
            articlelist: {
                $each: {
                    serialnumber: {
                        requiredIf: requiredIf(function(object) {
                            if (this.brand.toLowerCase() === 'hisense' && (object.productGroupCode === 'HV_TV' || object.productGroupCode === 'HB_TV')) {
                                return true;
                            }
                            return false;
                        }),
                        validSerialnumber: function(serialnumber, object) {
                            // only check for hisense and tv
                            if (this.brand.toLowerCase() === 'hisense' && (object.productGroupCode === 'HV_TV' || object.productGroupCode === 'HB_TV')) {
                                const regex = /^[A-Za-z0-9]{23}$/;
                                return regex.test(serialnumber);
                            } else {
                                return true;
                            }
                        }
                    },
                    sapBatch: {
                        requiredIf: requiredIf(function(object) {
                            if (object.batches.length === 0) {
                                return false;
                            }
                            return this.brand.toLowerCase() !== 'hisense';
                        })
                    }
                }
            },
            company: {
                isSet: function() {
                    if (this.userData.company.name && this.userData.company.city) {
                        return true;
                    } else if (this.userData.otherCompanyData) {
                        return true;
                    }
                    return false;
                }
            },
            zipcode: {
                required,
                maxLength: function() {
                    if (!this.userData.zipcode) {
                        return true;
                    }
                    return this.country === 'nl' ? this.userData.zipcode.length < 7 : this.userData.zipcode.length < 5;
                },
                alphaNum
            },
            housenumber: {
                required,
                numeric
            },
            installationdate: {
                required,
                dateIsInFuture: function() {
                    return moment() > moment(this.userData.installationdate);
                },
                dateTooOld: function() {
                    let diff = moment.duration(moment().diff(moment(this.userData.installationdate)));
                    let days = diff.asDays();
                    return days < 3650;
                }
            },
            phonenumber: {
                required: requiredIf(function() {
                    return !this.userData.phonenumber;
                })
            },
            emailaddress: {
                required,
                email
            },
            file: {
                fileSize: function() {
                    return this.userData.file ? this.userData.file.size < 1048576 : true;
                },
                required: requiredIf(function() {
                    return this.country === 'be' && this.userData.true;
                })
            },
            promotionfieldvalues: {
                $each: {
                    $each: {
                        value: {
                            required: requiredIf(function(fieldModel) {
                                // check for selected promotions
                                if (!this.IsPromotionSelected(fieldModel.promotionId, fieldModel.typenumber)) {
                                    return false;
                                }
                                return fieldModel.mandatory;
                            }),
                            checkCheckboxValue: function(value, fieldModel) {
                                // check for selected promotions
                                if (!this.IsPromotionSelected(fieldModel.promotionId, fieldModel.typenumber)) {
                                    return true;
                                }
                                if (fieldModel.inputType === 'checkbox' && fieldModel.mandatory) {
                                    return value;
                                } else {
                                    return true;
                                }
                            },
                            IBAN: function(value, fieldModel) {
                                // check for selected promotions
                                if (!this.IsPromotionSelected(fieldModel.promotionId, fieldModel.typenumber)) {
                                    return true;
                                }
                                if (fieldModel.inputType === 'IBAN' && (fieldModel.mandatory || value !== '')) {
                                    if (this.country === 'be') {
                                        const regex = /^BE\d{14}$/;
                                        return regex.test(value);
                                    } else if (this.country === 'nl') {
                                        const regex = /^NL\d{2}[A-Z]{4}\d{10}$/;
                                        // remove whitespaces and use uppercase to make it case insensitive
                                        return regex.test(value);
                                    } else {
                                        return true;
                                    }
                                } else {
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    mounted() {
        if (this.wizard.currentStep === 0) {
            document.body.classList.add('intropage');
        }
        this.checkForResultPageRedirect();
    },
    created: function() {
        // do we have a url promotion? (check after culture is selected)
        if (this.promotion !== null && this.promotion !== '' && this.hasCulture) {
            this.promotionLoading = true;
            axios
                .get(`/api/promotions/getByUrlParameter?p=` + this.promotion + `&c=` + this.country)
                .then(response => {
                    if (response.status === 200 && response.data !== null) {
                        this.promotionFoundInUrl = true;
                        // get promotion DTO and add the fields to the data-property 'userData.promotionfieldvalues'
                        this.promotionDtos[0] = response.data;
                        this.userData.promotionfieldvalues[0] = [];
                        this.promotionDtos[0].fields.forEach(field => {
                            this.userData.promotionfieldvalues[0].push({
                                id: field.id,
                                promotionId: this.promotionDtos[0].id,
                                inputType: field.inputType,
                                mandatory: field.mandatory,
                                label_name: field.labelKey_FieldName,
                                label_info: field.labelKey_InfoButton,
                                value: field.inputType === 'checkbox' ? false : ''
                            });
                        });
                        this.startWizard(false);
                        this.checkForResultPageRedirect();
                    }
                })
                .catch(e => {})
                .finally(() => {
                    this.promotionLoading = false;
                });
        }

        // get postal code service URL
        axios
            .get(`/api/garantie/getPostcodeServiceUrl?country=` + this.country)
            .then(response => {
                this.serviceUrl = response.data;
            })
            .catch(e => {});

        let thisObj = this;
        this.loaded = this.baseLocaleLoaded;
        // voorbeeld POC ophalen vertalingen
        this.$store.dispatch('setBrand', this.brand);
        this.$i18n.set(this.language + '-' + this.country.toUpperCase());
        this.$store.dispatch('setLanguage', this.language + '-' + this.country.toUpperCase() + '-' + this.brand.toLowerCase()).then(function() {
            thisObj.loaded = true;
        });
    }
};
</script>
