<template>
    <div v-if="v.userData.promotionfieldvalues.$each[promotionIndex]" class="c c-stap" id="promotionFields">
        <h3 class="c-stap__header" v-html="getPromotionInfoText()"></h3>
        <div
            v-if="promotions[promotionIndex].labelKey_PromotionText != null && promotions[promotionIndex].labelKey_PromotionText != ''"
            class="c-stap__body mt-1"
        >
            <p v-html="$tr(promotions[promotionIndex].labelKey_PromotionText)"></p>
        </div>
        <div class="c-stap__body">
            <div v-for="(v_field, index) in v.userData.promotionfieldvalues.$each[promotionIndex].$each.$iter" :key="index">
                <div class="form-group row">
                    <label
                        :class="
                            `col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label ${
                                v_field.$model.label_info != null && v_field.$model.label_info != '' ? 'hastooltip' : ''
                            }`
                        "
                    >
                        <span>
                            <span v-html="$tr(v_field.$model.label_name)"></span>
                            <span v-if="v_field.$model.mandatory" class="required-field">*</span>
                        </span>
                        <span v-if="v_field.$model.label_info != null && v_field.$model.label_info != ''" class="customtooltip">
                            <span class="tooltip-item"></span>
                            <span class="tooltip-content clearfix"><small v-html="$tr(v_field.$model.label_info)"></small></span>
                        </span>
                    </label>
                    <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                        <template v-if="v_field.$model.inputType == 'text'">
                            <input type="text" class="form-control" v-model="v_field.value.$model" maxlength="100" />
                        </template>
                        <template v-if="v_field.$model.inputType == 'IBAN'">
                            <input type="text" class="form-control" @input="formatInput($event, v_field)" v-model="v_field.value.$model" maxlength="100" />
                        </template>
                        <template v-if="v_field.$model.inputType == 'date'">
                            <date-picker v-model="v_field.value.$model" lang="lang" format="DD-MM-YYYY"></date-picker>
                        </template>
                        <template v-if="v_field.$model.inputType == 'checkbox'">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    :id="'checkbox_' + v_field.$model.id + promotionIndex"
                                    v-model="v_field.value.$model"
                                    class="custom-control-input"
                                />
                                <label :for="'checkbox_' + v_field.$model.id + promotionIndex" class="custom-control-label"></label>
                            </div>
                        </template>
                        <template v-if="v_field.$model.inputType == 'upload'">
                            <div>
                                <div class="c-device__photos">
                                    <div v-if="v_field.$model.value != null && v_field.$model.value != ''" class="c-device__photos--photo">
                                        <img :src="getBlobUrl(v_field.$model.value)" height="100" width="150" alt="" loading="lazy" />
                                        <button class="c-device__photos--deletephoto" @click="resetPhoto(v_field)"><i class="fal fa-times"></i></button>
                                    </div>
                                    <div v-else class="c-device__photos--addphoto" @click="$refs['file_' + v_field.$model.id + promotionIndex][0].click()">
                                        <i class="fal fa-camera"></i>
                                        <i class="fal fa-plus"></i>
                                    </div>
                                    <input
                                        class="d-none"
                                        type="file"
                                        :id="'file_' + v_field.$model.id + promotionIndex"
                                        :ref="'file_' + v_field.$model.id + promotionIndex"
                                        @change="handleFieldFileUpload(v_field)"
                                        accept="image/jpeg,image/png"
                                    />
                                </div>
                            </div>
                        </template>
                        <template v-if="v_field.$model.inputType == 'uploadAI'">
                            <div>
                                <div class="c-device__photos">
                                    <div v-if="v_field.$model.value != null && v_field.$model.value != ''" class="c-device__photos--photo">
                                        <!-- Conditioneel renderen van afbeelding of PDF icoon -->
                                        <img v-if="isImageFile(v_field.$model.value)" :src="getBlobUrl(v_field.$model.value)" height="100" width="150" alt="" loading="lazy" />
                                        <img v-else-if="isPdfFile(v_field.$model.value)" :src="`/img/PDF_file_icon.png`" alt="PDF icon" loading="lazy" style="object-fit: contain; width: 150px; height: 100px;"/>
                                        <button class="c-device__photos--deletephoto" @click="resetPhoto(v_field)"><i class="fal fa-times"></i></button>
                                    </div>
                                    <div v-else class="c-device__photos--addphoto" @click="$refs['file_' + v_field.$model.id + promotionIndex][0].click()">
                                        <i class="fal fa-camera"></i>
                                        <i class="fal fa-plus"></i>
                                    </div>
                                    <input class="d-none"
                                           type="file"
                                           :id="'file_' + v_field.$model.id + promotionIndex"
                                           :ref="'file_' + v_field.$model.id + promotionIndex"
                                           @change="handleFieldFileUpload(v_field)"
                                           accept="image/jpeg,image/png,application/pdf" />
                                </div>
                            </div>
                        </template>
                        <div class="text-sm mt-2 text-red" v-if="v_field.value.$error">
                            <div v-if="!v_field.value.required || !v_field.value.checkCheckboxValue">
                                <span v-html="$tr(v_field.$model.label_name)"></span>: {{ $tr('general.isrequired') }}
                            </div>
                            <div v-if="!v_field.value.IBAN"><span v-html="$tr(v_field.$model.label_name)"></span>: {{ $tr('general.isinvalidiban') }}</div>
                            <!--<div v-if="!v_field.fileSize"><span v-html="$tr(v_field.$model.label_name)"></span>: {{ $tr('general.max1mb') }}</div>-->
                        </div>
                    </div>
                </div>
            </div>
            <RequiredFieldExplanation />
        </div>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import RequiredFieldExplanation from './components/forms/required-field-explanation.vue';
import 'vue2-datepicker/index.css';

export default {
    name: 'step-promotionfields',
    components: { DatePicker, RequiredFieldExplanation },
    data() {
        return {
            lang: {
                formatLocale: {
                    firstDayOfWeek: 1
                },
                monthBeforeYear: false
            }
        };
    },
    props: {
        userData: {
            type: Object,
            default: null,
            required: true
        },
        brand: {
            type: String,
            default: null,
            required: true
        },
        promotions: {
            type: Array,
            required: true
        },
        v: {
            type: Object,
            default: null,
            required: true
        },
        promotionIndex: {
            type: Number,
            default: 0,
            required: true
        },
        promotionFoundInUrl: {
            type: Boolean,
            required: false
        }
    },
    methods: {
        // Controleert of het bestand een afbeelding is
        isImageFile(filename) {
            console.log(filename.name);
            return /\.(jpg|jpeg|png)$/i.test(filename.name);
        },
        // Controleert of het bestand een PDF is
        isPdfFile(filename) {
            console.log(filename.name);
            return /\.pdf$/i.test(filename.name);
        },
        formatInput(event, vField) {
            let value = event.target.value;
            value = value.replace(/\s/g, '');
            vField.value.$model = value.toUpperCase();
        },
        handleFieldFileUpload(vfield) {
            vfield.$model.value = this.$refs['file_' + vfield.$model.id + this.promotionIndex][0].files[0];
        },
        resetPhoto(vfield) {
            vfield.value.$model = null;
        },
        getBlobUrl(uploadedFile) {
            if (uploadedFile) return URL.createObjectURL(uploadedFile);
            else return '';
        },
        getPromotionInfoText() {
            let text = this.$tr('step2.promotioninfo');
            if (!this.promotionFoundInUrl) {
                text += ' ';
                text += this.promotions[this.promotionIndex].name;
            }
            return text;
        }
    }
};
</script>

<style lang="scss" scoped></style>
