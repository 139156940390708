<template>
    <div class="main intro-etna">
        <div class="container">
            <div class="c c-wizard__title text-center">
                <h1 v-html="getTitle"></h1>
                <h2 class="c-wizard__title--sub text-primary" v-if="getSubtitle != ''" v-html="getSubtitle"></h2>
            </div>
            <div class="row">
                <div class="d-none d-lg-block col-lg-3">
                    <svg
                        style="position: sticky; top: 100px;"
                        xmlns="http://www.w3.org/2000/svg"
                        width="226.446"
                        height="379.932"
                        viewBox="0 0 226.446 379.932"
                    >
                        <g opacity=".6">
                            <g>
                                <g fill="#fff">
                                    <path
                                        d="M208.05 125.455H71.394v60.444q8.76-9.636 24.528-15.768a93.448 93.448 0 0 1 34.164-6.132q32.85 0 54.531 14.892a87.645 87.645 0 0 1 31.755 38.542 129.4 129.4 0 0 1 10.074 51.246q0 51.246-28.908 81.249t-81.468 30q-35.04 0-60.882-12.045T15.33 334.381Q1.314 312.919 0 284.887h73.146a39.409 39.409 0 0 0 12.7 22.557q10.074 8.979 27.594 8.979 20.586 0 30.66-13.14t10.074-35.04q0-21.462-10.512-32.85t-30.654-11.388q-14.892 0-24.528 7.227a33.7 33.7 0 0 0-12.7 19.053H3.508V59.755H208.05Z"
                                    />
                                    <path
                                        d="M46.608.561v49.14h-11.97v-20.23h-18.62v20.23H4.048V.561h11.97v19.25h18.62V.561Zm32.48 0v49.14h-11.97V.561Zm53.2 15.54a8.953 8.953 0 0 0-3.815-3.745 12.482 12.482 0 0 0-5.845-1.3 12.026 12.026 0 0 0-9.31 3.815q-3.5 3.815-3.5 10.185 0 6.79 3.675 10.605t10.115 3.82a12.234 12.234 0 0 0 7.455-2.24 12.7 12.7 0 0 0 4.445-6.44h-15.19v-8.82h26.04v11.13a23.479 23.479 0 0 1-4.515 8.33 23.822 23.822 0 0 1-8.085 6.23 24.99 24.99 0 0 1-11.06 2.38 26.181 26.181 0 0 1-12.99-3.185 22.686 22.686 0 0 1-8.89-8.855 25.984 25.984 0 0 1-3.18-12.95 26.181 26.181 0 0 1 3.185-12.985 22.686 22.686 0 0 1 8.855-8.89 25.985 25.985 0 0 1 12.95-3.185q8.82 0 14.875 4.27a20.151 20.151 0 0 1 8.015 11.83Zm75.18-15.54v49.14h-11.97v-20.23h-18.62v20.23h-11.97V.561h11.97v19.25h18.62V.561Z"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="col-12 col-lg-9">
                    <div class="c c-stap">
                        <h3 class="c-stap__header">
                            <span v-html="$tr('intropage.header.blocks.title')"></span>
                        </h3>
                        <div class="c-stap__body">
                            <ul class="c-usplist">
                                <li>
                                    <i class="fal fa-clock"></i>
                                    <span v-html="$tr('intropage.header.blocks.item1')"></span>
                                </li>
                                <li>
                                    <i class="fal fa-barcode-read"></i>
                                    <span v-html="$tr('intropage.header.blocks.item2')"></span>
                                </li>
                                <li>
                                    <i class="fal fa-clipboard-user"></i>
                                    <span v-html="$tr('intropage.header.blocks.item3')"></span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="c c-stap">
                        <template>
                            <h3><span v-html="$tr('intropage.block1.title')"></span></h3>
                            <p>
                                <span v-html="$tr('intropage.block1.subtext')"></span>
                            </p>
                            <h4><span v-html="$tr('intropage.block1.list.title')"></span></h4>
                            <ul class="c-arrowlist">
                                <li>
                                    <span v-html="$tr('intropage.block1.list.item1')"></span>
                                </li>
                                <li><span v-html="$tr('intropage.block1.list.item2')"></span></li>
                            </ul>
                            <p>
                                <span v-html="$tr('intropage.block1.list.subtext')"></span>
                            </p>
                        </template>
                        <p><span v-html="$tr('intropage.disclaimerlinks')"></span></p>
                    </div>                    
                    <div class="c c-stap">
                        <p><span v-html="$tr('intropage.bottom.text')"></span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-stickybtn">
            <button @click="$emit('start')" class="btn btn-primary">
                <span v-html="$tr('wizard.button.register')"></span>
                <i class="fal fa-arrow-right"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'intropage-etna',
    data() {
        return {};
    },
    props: {
        lang: {
            type: String,
            required: true
        }
    },
    mounted() {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        let product = params.get('modelnummer');
        if (product) {
            this.$emit('start');
        }
    },
    computed: {
        getTitle() {
            return this.$tr('intropage.title');
        },
        getSubtitle() {
            return this.$tr('intropage.subtitle');
        }
    }
};
</script>
