<template>
    <div class="intro-asko">
        <div class="c-intropage__hero">
            <img src="/img/asko/intro-asko-hero.jpg" class="img-fluid" alt="ASKO hero image" />
        </div>
        <div class="c c-intropage__content">
            <div class="container">
                <div class="c-intropage__content--inner">
                    <div class="c c-intropage__title text-center">
                        <h1 v-html="getTitle"></h1>
                        <h2 class="c-wizard__title--sub" v-if="getSubtitle != ''" v-html="getSubtitle"></h2>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-12">
                            <ul class="c-grid-usplist">
                                <li>
                                    <i class="fal fa-clock"></i>
                                    <span v-html="$tr('intropage.header.blocks.item1')"></span>
                                </li>
                                <li>
                                    <i class="fal fa-barcode-read"></i>
                                    <span v-html="$tr('intropage.header.blocks.item2')"></span>
                                </li>
                                <li>
                                    <i class="fal fa-clipboard-user"></i>
                                    <span v-html="$tr('intropage.header.blocks.item3')"></span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="c c-imgblock">
                                <img src="/img/asko/intro-asko1.jpg" alt="Asko 1" loading="lazy" class="img-fluid" />
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="c c-intropage__text">
                                <template>
                                    <h3><span v-html="$tr('intropage.block1.title')"></span></h3>
                                    <p>
                                        <span v-html="$tr('intropage.block1.subtext')"></span>
                                    </p>
                                    <h4><span v-html="$tr('intropage.block1.list.title')"></span></h4>
                                    <ul class="c-arrowlist">
                                        <li><span v-html="$tr('intropage.block1.list.item1')"></span></li>
                                        <li>
                                            <span v-html="$tr('intropage.block1.list.item2')"></span>
                                        </li>
                                    </ul>
                                </template>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="c c-intropage__text">
                                <template>
                                    <h2><span v-html="$tr('intropage.block4.title')"></span></h2>
                                    <p>
                                        <span v-html="$tr('intropage.block4.text')"></span>
                                    </p>
                                    <p>
                                        <span v-html="$tr('intropage.disclaimerlinks')"></span>
                                    </p>
                                </template>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="c c-imgblock text-center">
                                <img src="/img/asko/intro-asko2.jpg" alt="Asko 2" loading="lazy" class="img-fluid" />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="c c-intropage__text">
                                <p>
                                    <span v-html="$tr('intropage.bottom.text')"></span>
                                </p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-stickybtn">
            <button @click="$emit('start')" class="btn btn-secondary">
                <span v-html="$tr('wizard.button.register')"></span>
                <i class="fal fa-arrow-right"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'intropage-asko',
    data() {
        return {};
    },
    props: {
        lang: {
            type: String,
            required: true
        }
    },
    mounted() {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        let product = params.get('modelnummer');
        if (product) {
            this.$emit('start');
        }
    },
    computed: {
        getTitle() {
            return this.$tr('intropage.title');
        },
        getSubtitle() {
            return this.$tr('intropage.subtitle');
        }
    }
};
</script>
