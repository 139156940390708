<template>
    <div class="c c-stap" v-if="showAddDevice">
        <div class="c-stap__body">
            <div class="c-adddevice">
                <div class="c-adddevice__heading">
                    <h3>{{ $tr('wizard.step1.title.singular') }}</h3>
                    <p>{{ $tr('step3.introtext') }}</p>
                </div>
                <div class="c-adddevice__row">
                    <template v-if="shouldShowScanner">
                        <div class="c-adddevice__col col1">
                            <div class="c-adddevice__col--qrscanner">
                                <h4 class="c-adddevice__header">{{ $tr('step3.qrscanner.title') }}</h4>
                                <p v-html="$tr('step3.qrscanner.text')"></p>
                                <button class="btn btn-primary" @click="openQRScanner">
                                    <i class="fal fa-camera"></i><span>{{ $tr('step3.qrscanner.buttontext') }}</span>
                                </button>
                                <div class="c-adddevice__notfound" v-if="productNotFound">{{ $tr('step3.productnotfound') }}</div>
                                <br />
                                <img
                                    src="/img/qrcode-phone.jpg"
                                    height="136"
                                    width="136"
                                    alt="Example of mobile phone with qr scanner"
                                    style="margin-top: 20px;"
                                />
                            </div>
                            <div class="c-adddevice__col--qrcode">
                                <div class="c-adddevice__col--qrcode--text">
                                    <h4 class="c-adddevice__header">{{ $tr('step3.qrcode.title') }}</h4>
                                    <p v-html="$tr('step3.qrcode.text')"></p>
                                </div>
                                <div class="c-adddevice__col--qrcode--images">
                                    <div v-html="qrImageString" class="c-adddevice__col--qrcode--img"></div>
                                    <img src="/img/qrcode-phone.jpg" height="136" width="136" alt="Example of mobile phone with qr scanner" />
                                </div>
                            </div>
                        </div>
                        <div class="c-adddevice__seperator">
                            <span>{{ $tr('step3.or') }}</span>
                        </div>
                    </template>
                    <div class="c-adddevice__col col2">
                        <div class="c-adddevice__form">
                            <h4 class="c-adddevice__header" v-if="shouldShowScanner">{{ $tr('step3.addmanual.title') }}</h4>
                            <label for="inputArticleNumber" class="c-adddevice__label">
                                <span>{{ $tr('step3.modelnummer') }}*</span>
                                <span class="customtooltip">
                                    <span class="tooltip-item"></span>
                                    <span class="tooltip-content clearfix">
                                        <img :src="`/img/modelnummer-tooltip.png`" /><small>{{ $tr('wizard.modelnumber.tooltip') }}</small>
                                    </span>
                                </span>
                            </label>
                            <div class="c-adddevice__field">
                                <autocomplete
                                    id="products"
                                    anchor="productId"
                                    param="articleNumber"
                                    :placeholder="this.userData.language === 'FR' ? 'par example CX4612D' : 'bijvoorbeeld CX4612D'"
                                    url="api/garantie/getProductsByArticleNumberNoVersion"
                                    :classes="{ input: 'form-control' }"
                                    label=""
                                    :min="2"
                                    :customParams="{
                                        category: this.selectedCategory,
                                        language: this.userData.country.toUpperCase(),
                                        brand: this.brand,
                                        promotionUrlParam: this.getPromotionUrlParam()
                                    }"
                                    :on-select="setArticleNumber"
                                ></autocomplete>
                                <div class="c-adddevice__loader" :class="{ loading: isLoading }">
                                    <span class="btn-loader"><i class="fal fa-spinner-third"></i></span>
                                </div>
                            </div>
                            <div class="c-adddevice__field--footer text-muted">
                                <small>{{ $tr('wizard.inhoofdletters') }}</small>
                                <small v-html="$tr('modelnummervoettekst')"></small>
                            </div>
                            <div class="c-adddevice__notfound" v-if="productNotFound">{{ $tr('step3.productnotfound') }}</div>
                        </div>
                        <!--<RequiredFieldExplanation />-->
                    </div>
                </div>
            </div>
        </div>
        <div id="qrscanner-modal" class="c-adddevice__modal" v-show="showQrScanner">
            <div class="c-adddevice__modal--dialog">
                <div class="c-adddevice__modal--content">
                    <div class="c-adddevice__modal--header">
                        <h3>{{ $tr('qrscanner.title') }}</h3>
                        <button type="button" class="c-adddevice__modal--close" aria-label="Close" @click="destroyQRScanner">
                            <span aria-hidden="true"><i class="fal fa-times"></i></span>
                        </button>
                    </div>
                    <div class="c-adddevice__modal--body">
                        <div class="qr-wrapper">
                            <div id="qr-reader"></div>
                            <div class="qr-wrapper-box">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div class="c-adddevice__qr--selectcam" v-if="cameraList.length >= 0">
                            <select class="form-control" @change="changeQRcamera(selectedCamera)" v-model="selectedCamera">
                                <option value="" disabled>{{ $tr('qrscanner.switchbutton') }}</option>
                                <option v-for="camera in cameraList" :key="camera.id" :value="camera.id">{{ camera.label }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import articleHelper from '../../util/articleHelper';
// import RequiredFieldExplanation from './components/forms/required-field-explanation.vue';
import { Html5Qrcode } from 'html5-qrcode';

export default {
    name: 'step-add-devices',
    data() {
        return {
            products: null,
            articleNumber: '', // null,
            selectedBatch: null,
            productNotFound: false,
            isLoading: false,
            categories: [],
            selectedCategory: '',
            qrScanner: {},
            showQrScanner: false,
            qrImageString: '',
            cameraList: [],
            selectedCamera: '',
            qrScannerLock: false
        };
    },
    mounted() {
        let url = window.location.href;
        url += '&select';
        let qr = require('qr-image');
        this.qrImageString = qr.imageSync(url, { type: 'svg' });

        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        let product = params.get('modelnummer');
        if (product) {
            this.getArticle(product);
        }

        axios.get(`/api/garantie/getCategories?language=${this.userData.language}`).then(response => {
            if (response.data) {
                let fetchedCategories = response.data;
                this.categories = fetchedCategories
                    .filter(categorie => {
                        return categorie.description.length > 0;
                    })
                    .map(categorie => ({
                        key: categorie.productGroup,
                        value: categorie.description
                    }));
            }
        });
    },
    methods: {
        onScanSuccess(result) {
            if (!this.qrScannerLock) {
                // lock result parse to prevent double scan
                this.qrScannerLock = true;

                let decodedText = result;
                // example qr content
                // decodedText = 'https://qr.atag.nl/appliance/0000000000007398500002202300031130005?lang=nl';

                // remove domain from url
                let splitArray = decodedText.split('/');
                let fullCode = splitArray[splitArray.length - 1];

                // remove lang=x querystring
                splitArray = fullCode.split('?');
                let code = splitArray[0];

                // Strip code
                code = code.substring(12);
                let sapNumber = code.substring(0, 6);
                code = code.substring(6); // remove sapNumber
                code = code.substring(2); // remove slashes
                let versionNumber = code.substring(0, 2);
                code = code.substring(2); // remove version number
                code = code.substring(4); // remove year
                code = code.substring(3); // remove 000
                let serialNumber = code;

                // Get article
                this.getArticle(sapNumber, serialNumber, versionNumber);

                // desproy QR Scanner
                this.destroyQRScanner();
                this.qrScannerLock = false;
            }
        },
        destroyQRScanner() {
            this.showQrScanner = false;
            this.qrScanner.stop();
            this.qrScanner = null;

            this.setBodyClass('modalopen', false);
        },
        openQRScanner() {
            // https://scanapp.org/html5-qrcode-docs/docs/intro
            // De 'qr-reader' verwijst naar het id van het element waar de camera feed getoond moet worden.
            this.showQrScanner = true;
            this.setBodyClass('modalopen', true);

            // This method will trigger user permissions
            Html5Qrcode.getCameras()
                .then(devices => {
                    if (devices && devices.length) {
                        this.cameraList = devices;
                        this.qrScanner = new Html5Qrcode('qr-reader');
                        const qrCodeSuccessCallback = (decodedText, decodedResult) => {
                            this.onScanSuccess(decodedText);
                        };
                        const config = { fps: 25, aspectRatio: 1 / 1 };

                        if (this.selectedCamera === '') {
                            this.qrScanner.start({ facingMode: 'environment' }, config, qrCodeSuccessCallback);
                        } else {
                            this.qrScanner.start({ deviceId: { exact: this.selectedCamera } }, config, qrCodeSuccessCallback);
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        changeQRcamera(id) {
            this.selectedCamera = id;
            this.qrScanner.stop();
            this.openQRScanner();
        },
        setBodyClass(key, bool) {
            if (bool) document.body.classList.add(key);
            else document.body.classList.remove(key);
        },
        setArticleNumber(value) {
            this.articleNumber = value.productId;
            this.search();
        },
        reformatArticleNumber(value) {
            return articleHelper.toReadableArticleNumber(value);
        },
        reformatModelNumber(value) {
            return articleHelper.toReadableModelNumber(value);
        },
        getPromotionUrlParam() {
            if (this.promotionFoundInUrl && this.promotions[0] != null) return this.promotions[0].url;

            return '';
        },
        getArticle(articleNumber, serialNumber, versionNumber) {
            this.isLoading = true;
            axios
                .get(`/api/garantie/getProductsByArticleNumber?articleNumber=${articleNumber}&language=${this.userData.language}&brand=${this.brand}`)
                .then(response => {
                    if (response.data) {
                        this.products = response.data;
                        if (this.products.length === 0) {
                            this.productNotFound = true;
                            this.isLoading = false;
                        } else {
                            let article = {
                                articlenumber: this.products[0].productId,
                                batchNumber: this.products[0].sapMaterial,
                                serialnumber: '',
                                description: this.products[0].description,
                                batches: this.products.map(function(obj) {
                                    return obj.sapBatch;
                                }),
                                productGroup: this.products[0].productGroup,
                                productGroupCode: this.products[0].productGroupCode,
                                checkForDuplicates: true
                            };
                            if (serialNumber) {
                                article.serialnumber = serialNumber;
                            }
                            if (versionNumber) {
                                article.sapBatch = versionNumber;
                            } else if (article.batches.length === 1) {
                                article.sapBatch = article.batches[0];
                            }
                            this.userData.articlelist.push(article);
                            this.userData.articleListOrDealerChanged = true;
                            this.articleNumber = null;
                            this.productNotFound = false;
                            this.$emit('hideAddDevice');
                            this.isLoading = false;
                            this.selectedCategory = '';
                        }
                    }
                })
                .catch(e => {});
        },
        search() {
            this.getArticle(this.articleNumber);
        },
        getData(value) {
            return this.cities;
        },
        deleteArticle(index) {
            this.userData.articlelist.splice(index, 1);
            this.userData.articleListOrDealerChanged = true;
        }
    },
    components: {
        // RequiredFieldExplanation
    },
    computed: {
        // noProductsAdded() {
        //     return this.userData.articlelist.length === 0;
        // },
        // addAnotherDisabled() {
        //     return this.selectedBatch === null || this.selectedBatch === undefined;
        // }
        shouldShowScanner() {
            return false;
            // return this.brand.toLowerCase() !== 'hisense';
        }
    },
    props: {
        brand: {
            type: String,
            default: null,
            required: true
        },
        showAddDevice: {
            type: Boolean,
            default: true,
            required: false
        },
        userData: {
            type: Object,
            default: null,
            required: true
        },
        promotions: {
            type: Array,
            required: false
        },
        promotionFoundInUrl: {
            type: Boolean,
            required: false
        },
        v: {
            type: Object,
            default: null,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
