<template>
    <div class="intro-hisense">
        <div class="c-intropage__hero">
            <img src="/img/hisense/intro-hisense-hero.jpg" class="img-cover" alt="Hisense hero image" />
            <div class="c-intropage__hero--overlay">
                <div class="container">
                    <div class="c-intropage__hero--title text-center">
                        <h1 v-html="getTitle"></h1>
                        <h2 class="c-wizard__title--sub" v-if="getSubtitle != ''" v-html="getSubtitle"></h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="c c-intropage__content" style="background-color:#fff;">
            <div class="container">
                <ul class="c-grid-usplist">
                    <li>
                        <i class="fal fa-clock"></i>
                        <span v-html="$tr('intropage.header.blocks.item1')"></span>
                    </li>
                    <li>
                        <i class="fal fa-barcode-read"></i>
                        <span v-html="$tr('intropage.header.blocks.item2')"></span>
                    </li>
                    <li>
                        <i class="fal fa-clipboard-user"></i>
                        <span v-html="$tr('intropage.header.blocks.item3')"></span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="c c-intropage__content">
            <div class="container">
                <div class="c-intropage__content--inner">
                    <div class="row gutters-lg-0">
                        <div class="col-12 col-lg-6">
                            <div class="c c-imgblock">
                                <img src="/img/hisense/intro-hisense1.jpg" alt="Hisense 1" loading="lazy" class="img-fluid" />
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="c c-intropage__text has-imgleft">
                                <template>
                                    <h3><span v-html="$tr('intropage.block1.title')"></span></h3>
                                    <p>
                                        <span v-html="$tr('intropage.block1.subtext')"></span>
                                    </p>
                                </template>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="c c-intropage__text">
                                <template>
                                    <h2><span v-html="$tr('intropage.block1.list.title')"></span></h2>
                                    <p>
                                        <span v-html="$tr('intropage.block1.list.text')"></span>
                                    </p>
                                </template>
                                <p><span v-html="$tr('intropage.disclaimerlinks')"></span></p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="c c-imgblock">
                                <img src="/img/hisense/intro-hisense2.jpg" alt="Hisense 2" loading="lazy" class="img-fluid" />
                            </div>
                        </div>
                        <div class="col-12">
                            <div>
                                <p><span v-html="$tr('intropage.bottom.text')"></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-stickybtn">
            <button @click="$emit('start')" class="btn btn-primary">
                <span v-html="$tr('wizard.button.register')"></span>
                <i class="fal fa-arrow-right"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'intropage-hisense',
    data() {
        return {};
    },
    props: {
        lang: {
            type: String,
            required: true
        }
    },
    mounted() {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        let product = params.get('modelnummer');
        if (product) {
            this.$emit('start');
        }
    },
    computed: {
        getTitle() {
            return this.$tr('intropage.title');
        },
        getSubtitle() {
            const subtitle =  this.$tr('intropage.subtitle');
            if(subtitle === '[intropage.subtitle]'){
                return '';
            }
            return subtitle;
        }
    }
};
</script>
