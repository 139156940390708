<template>
    <div :class="['c-device__item', { editable: editable }]">
        <div v-if="checkDuplicates" class="c-device__item--warning">
            <div v-if="checkDuplicates" class="c-device__item--warning--inner">
                <h3>{{ $tr('wizard.articles.duplicate.title') }}</h3>
                <p>{{ $tr('wizard.articles.duplicate.text') }}</p>

                <div class="btn-grid">
                    <button class="btn btn-success" @click="clickAddDuplicate">{{ $tr('wizard.articles.duplicate.addbuttontext') }}</button>
                    <button class="btn btn-danger" @click="clickRemoveDuplicate">{{ $tr('wizard.articles.duplicate.removebuttontext') }}</button>
                </div>
            </div>
        </div>

        <div class="c-device__inner">
            <div :class="['c-device__img', { 'order-lg-2': imageRight }]">
                <img
                    :src="`https://www.atagonline.nl/beeldenbank?imageName=${reformatModelNumber(article.articlenumber)}`"
                    class="img-fluid"
                    alt="img"
                    v-if="!hideImage"
                    @error="altImageUrl"
                />
            </div>
            <div :class="['c-device__info', { 'order-lg-1': imageRight }]">
                <div class="c-device__row">
                    <div class="c-device__lbl">
                        <span>{{ $tr('step3.modelnummer') }}</span>
                        <span class="customtooltip" v-if="editable">
                            <span class="tooltip-item"></span>
                            <span class="tooltip-content clearfix">
                                <img :src="`/img/modelnummer-tooltip.png`" /><small>{{ $tr('step3.modelnummertooltip') }}</small>
                            </span>
                        </span>
                    </div>
                    <div class="c-device__val">{{ reformatModelNumber(article.articlenumber) }}</div>
                </div>
                <div class="c-device__row" v-if="brand != 'hisense'">
                    <div class="c-device__lbl">
                        {{ $tr('step3.articlenumber') }}
                        <span class="customtooltip" v-if="editable">
                            <span class="tooltip-item"></span>
                            <span class="tooltip-content clearfix">
                                <img :src="`/img/artikelnummer-tooltip.png`" />
                                <small>{{ $tr('step3.artikelnummertooltip') }}</small>
                            </span>
                        </span>
                    </div>
                    <div class="c-device__val">{{ reformatArticleNumber(article.batchNumber) }}</div>
                </div>
                <div class="c-device__row" v-if="brand != 'hisense'">
                    <div class="c-device__lbl">
                        {{ $tr('step3.versionnumber') }} <span>*</span>
                        <span class="customtooltip" v-if="editable">
                            <span class="tooltip-item"></span>
                            <span class="tooltip-content clearfix">
                                <img :src="`/img/versienummer-tooltip.png`" />
                                <small>{{ $tr('step3.versionnumbertooltip') }}</small>
                            </span>
                        </span>
                    </div>
                    <div class="c-device__val">
                        <template v-if="editable">
                            <select
                                id="inputState"
                                class="form-control form-small"
                                v-model="userData.articlelist[index].sapBatch"
                                @change="v.userData.articlelist.$each[index].sapBatch.$touch()"
                            >
                                <option v-for="batch in article.batches" :key="batch">{{ batch }}</option>
                            </select>
                        </template>
                        <template v-else>
                            {{ userData.articlelist[index].sapBatch }}
                        </template>
                    </div>
                </div>
                <div class="c-device__row">
                    <div class="c-device__lbl">
                        {{ $tr('step3.serialnumber') }} <span v-if="serialnumberRequired">*</span>
                        <span class="customtooltip" v-if="editable">
                            <span class="tooltip-item"></span>
                            <span class="tooltip-content clearfix">
                                <img v-if="brand != 'hisense'" :src="`/img/serienummer-tooltip.png`" />
                                <small>{{ $tr('step3.serialnumbertooltip') }}</small>
                            </span>
                        </span>
                    </div>
                    <div class="c-device__val">
                        <template v-if="editable">
                            <input
                                type="text"
                                class="form-control"
                                @input="v.userData.articlelist.$each[index].serialnumber.$touch()"
                                v-model="userData.articlelist[index].serialnumber"
                                :placeholder="$tr('step3.serialnumber')"
                            />
                        </template>
                        <template v-else>{{ userData.articlelist[index].serialnumber }}</template>
                    </div>
                </div>
                <div class="text-sm mt-2 text-red" v-if="!v.userData.articlelist.$each[index].serialnumber.requiredIf">
                    <div>{{ $tr('step1.serialnumber') }}: {{ $tr('general.isrequired') }}</div>
                </div>
                <div class="text-sm mt-2 text-red" v-else-if="!v.userData.articlelist.$each[index].serialnumber.validSerialnumber">
                    <div>{{ $tr('step1.serialnumber') }}: {{ $tr('general.isinvalid') }}</div>
                </div>
                <div class="text-sm mt-2 text-red" v-if="!v.userData.articlelist.$each[index].sapBatch.requiredIf">
                    <div>{{ $tr('step3.versionnumber') }}: {{ $tr('general.isrequired') }}</div>
                </div>
                <RequiredFieldExplanation />
            </div>
        </div>
        <div class="c-device__delete" v-if="editable">
            <button title="Verwijder" class="btn btn-link btn-hasicon" @click="deleteArticle()">
                <i class="fal fa-trash-alt"></i>
                <span>{{ $tr('step3.delete') }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import articleHelper from '../../../util/articleHelper';
import RequiredFieldExplanation from './forms/required-field-explanation.vue';

export default {
    name: 'device',
    components: {
        RequiredFieldExplanation
    },
    data() {
        return {
            hideImage: false
        };
    },
    props: {
        editable: {
            type: Boolean,
            default: true,
            required: false
        },
        index: {
            type: Number,
            required: true
        },
        article: {
            type: Object,
            required: true
        },
        brand: {
            type: String,
            required: true
        },
        imageRight: {
            type: Boolean,
            default: false,
            required: false
        },
        userData: {
            type: Object,
            required: true
        },
        v: {
            type: Object,
            default: null,
            required: true
        }
    },
    computed: {
        checkDuplicates() {
            if (this.article.checkForDuplicates) {
                const getDuplicateArticles = this.userData.articlelist.filter(n => n.articlenumber === this.article.articlenumber);
                if (getDuplicateArticles.length > 1) {
                    this.setBodyClass('duplicatemodal', true);
                    return true;
                }
            }

            this.disableDuplicateCheck();

            return false;
        },
        serialnumberRequired() {
            if (this.brand.toLowerCase() === 'hisense' && (this.article.productGroupCode === 'HV_TV' || this.article.productGroupCode === 'HB_TV')) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        altImageUrl(event) {
            event.target.src = 'https://www.atagonline.nl/beeldenbank?imageName=unknown';
            this.hideImage = true;
        },
        reformatArticleNumber(value) {
            return articleHelper.toReadableArticleNumber(value);
        },
        reformatModelNumber(value) {
            return articleHelper.toReadableModelNumber(value);
        },
        deleteArticle() {
            this.userData.articlelist.splice(this.index, 1);
            this.userData.articleListOrDealerChanged = true;
        },
        disableDuplicateCheck() {
            this.article.checkForDuplicates = false;
        },
        clickAddDuplicate() {
            this.disableDuplicateCheck();
            this.setBodyClass('duplicatemodal', false);
        },
        clickRemoveDuplicate() {
            this.deleteArticle();
            this.setBodyClass('duplicatemodal', false);
        },
        setBodyClass(key, bool) {
            if (bool) document.body.classList.add(key);
            else document.body.classList.remove(key);
        }
    }
};
</script>
