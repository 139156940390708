<template>
    <div class="c c-stap" id="purchaseLocation">
        <h3 class="c-stap__header">{{ $tr('step2.purchaselocation') }}</h3>
        <div class="c-stap__body">
            <div class="form-group row">
                <label for="inlineRadioOptions" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">{{ $tr('step2.bought') }}</label>
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            checked
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            v-model="isOnlineStore"
                            v-on:change="clearAC()"
                            value="0"
                        />
                        <label class="form-check-label" for="inlineRadio1">{{ $tr('step2.store') }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="1"
                            v-model="isOnlineStore"
                            v-on:change="clearAC()"
                        />
                        <label class="form-check-label" for="inlineRadio2">{{ $tr('step2.online') }}</label>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="companies" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">
                    {{ $tr('step2.companylocation') }}
                    <span class="required-field">*</span>
                </label>
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8 " data-autocomplete="wide">
                    <autocomplete
                        id="companies"
                        anchor="accountnaam"
                        :placeholder="autocompletePlaceholder"
                        label="city"
                        ref="companies"
                        :url="isOnlineStore === '1' ? '/api/garantie/getonlineshops' : '/api/garantie/getcities'"
                        :on-select="onSelect"
                        :classes="{ input: otherCompany && isOnlineStore !== '1' ? 'form-control disabled' : 'form-control' }"
                        :customParams="{ brand: brand, country: this.userData.country.toUpperCase(), promotionUrl: computedPromotionUrl }"
                    ></autocomplete>

                    <template v-if="isOnlineStore !== '1'">
                        <div class="form-check">
                            <input class="custom-control-input" type="checkbox" value v-model="otherCompany" v-on:click="clearAC()" id="otherCompany" />
                            <label class="custom-control-label" for="otherCompany">{{ $tr('step2.othercompany') }}</label>
                        </div>
                        <input
                            type="text"
                            class="form-control mt-2"
                            id="otherCompanyData"
                            v-if="otherCompany"
                            v-model="userData.otherCompanyData"
                            :disabled="!otherCompany"
                            @blur="otherCompanyDataSelected"
                        />
                    </template>
                    <div class="text-sm mt-2 text-red" v-if="v.userData.company.$error">
                        {{ $tr('step2.companylocation.field') }}: {{ $tr('general.isrequired') }}
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="dag" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label"
                    >{{ $tr('step2.installdate') }} <span class="required-field">*</span></label
                >
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <date-picker :disabled-date="beforeDateExclude" v-model="userData.installationdate" lang="lang" format="DD-MM-YYYY"></date-picker>
                    <div class="text-sm mt-2 text-red" v-if="v.userData.installationdate.$error">
                        <div v-if="!v.userData.installationdate.required">{{ $tr('step2.installdate') }}: {{ $tr('general.isrequired') }}</div>
                        <template v-else>
                            <div v-if="!v.userData.installationdate.dateIsInFuture">{{ $tr('step2.installdate') }}: {{ $tr('step2.dateisinfuture') }}</div>
                            <div v-if="!v.userData.installationdate.dateTooOld">{{ $tr('step2.installdate') }}: {{ $tr('step2.datetooold') }}</div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="ordernumber" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">{{ $tr('step2.ordernumber') }}</label>
                <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                    <input type="text" class="form-control" id="ordernumber" v-model="userData.ordernumber" @blur="getProductsForOrder()" />
                </div>
            </div>
            <RequiredFieldExplanation />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import DatePicker from 'vue2-datepicker';
import RequiredFieldExplanation from './components/forms/required-field-explanation.vue';
import 'vue2-datepicker/index.css';

export default {
    name: 'step-purchaselocation',
    components: { DatePicker, RequiredFieldExplanation },
    data() {
        return {
            lastOtherCompany: '',
            otherCompany: false,
            isOnlineStore: 0,
            lang: {
                formatLocale: {
                    firstDayOfWeek: 1
                },
                monthBeforeYear: false
            }
        };
    },
    props: {
        userData: {
            type: Object,
            default: null,
            required: true
        },
        brand: {
            type: String,
            default: null,
            required: true
        },
        v: {
            type: Object,
            default: null,
            required: true
        },
        promotionFoundInUrl: {
            type: Boolean,
            required: false
        },
        promotionUrl: {
            type: String,
            default: '',
            required: true
        }
    },
    computed: {
        autocompletePlaceholder() {
            if (this.userData.company.name) {
                return this.userData.company.name;
            } else {
                if (this.isOnlineStore) {
                    return this.$tr('step2.searchdomainname');
                } else {
                    return this.$tr('step2.searchstoreorcity');
                }
            }
        },
        computedPromotionUrl() {
            if (this.promotionFoundInUrl) {
                return this.promotionUrl;
            } else {
                return '';
            }
        }
    },

    created() {
        if (!this.promotionFoundInUrl && this.userData.articleListOrDealerChanged && this.userData.company.name) {
            this.$emit('setPromotionDtosWithTypenumberAndDealer');
        }
    },
    methods: {
        beforeDateExclude(date) {
            var d = new Date();
            var dNow = new Date();
            d.setDate(d.getDate() - 3650);
            return date < d || date > dNow;
        },
        onSelect(value) {
            this.userData.company.name = value.accountnaam;
            this.userData.company.city = value.city;
            this.userData.otherCompanyData = null;
            this.userData.articleListOrDealerChanged = true;
            if (!this.promotionFoundInUrl) {
                this.$emit('setPromotionDtosWithTypenumberAndDealer');
            }
        },
        clearAC() {
            this.userData.company.name = null;
            this.userData.company.city = null;
            this.userData.otherCompanyData = null;
            this.userData.articleListOrDealerChanged = true;
            this.$refs.companies.setValue('');
        },
        otherCompanyDataSelected() {
            if (!this.promotionFoundInUrl && this.lastOtherCompany !== this.userData.otherCompanyData) {
                this.userData.articleListOrDealerChanged = true;
                this.$emit('setPromotionDtosWithTypenumberAndDealer');
            }
            this.lastOtherCompany = this.userData.otherCompanyData;
        },
        getProductsForOrder() {
            axios
                .get(`/api/garantie/GetProductsByOrderNumber?orderNumber=${this.userData.ordernumber}&language=${this.userData.language}`)
                .then(response => {
                    if (response.data) {
                        let products = response.data;
                        products.forEach(product => {
                            let article = {
                                articlenumber: product.productId,
                                batchNumber: product.sapMaterial,
                                serialnumber: '',
                                description: product.description,
                                sapBatch: product.sapBatch,
                                batches: product.batches
                            };
                            this.userData.articlelist.push(article);
                            this.userData.articleListOrDealerChanged = true;
                        });
                    }
                })
                .catch(e => {});
        }
    }
};
</script>

<style lang="scss" scoped></style>
