<template>
    <div class="main c-language">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="c c-wizard__title text-center">
                        <h1><span v-html="$tr('set.language.page.title')"></span></h1>
                    </div>
                    <div class="c c-stap">
                        <div class="c-stap__body">
                            <div class="c-language__options">
                                <button type="button" class="c-language__options--item" @click="$emit('setCulture', 'nl', 'nl')">
                                    <div class="c-language__options--flag">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-nl" viewBox="0 0 640 480">
                                            <path fill="#21468b" d="M0 0h640v480H0z" />
                                            <path fill="#fff" d="M0 0h640v320H0z" />
                                            <path fill="#ae1c28" d="M0 0h640v160H0z" />
                                        </svg>
                                    </div>
                                    <div class="c-language__options--name"><span v-html="$tr('set.language.page.netherlands')"></span></div>
                                </button>
                                <button type="button" class="c-language__options--item" @click="$emit('setCulture', 'be', 'nl')">
                                    <div class="c-language__options--flag">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-be" viewBox="0 0 640 480">
                                            <g fill-rule="evenodd" stroke-width="1pt">
                                                <path d="M0 0h213.3v480H0z" />
                                                <path fill="#ffd90c" d="M213.3 0h213.4v480H213.3z" />
                                                <path fill="#f31830" d="M426.7 0H640v480H426.7z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="c-language__options--name"><span v-html="$tr('set.language.page.belgium.nl')"></span></div>
                                </button>
                                <button type="button" class="c-language__options--item" @click="$emit('setCulture', 'be', 'fr')">
                                    <div class="c-language__options--flag">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-be" viewBox="0 0 640 480">
                                            <g fill-rule="evenodd" stroke-width="1pt">
                                                <path d="M0 0h213.3v480H0z" />
                                                <path fill="#ffd90c" d="M213.3 0h213.4v480H213.3z" />
                                                <path fill="#f31830" d="M426.7 0H640v480H426.7z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="c-language__options--name"><span v-html="$tr('set.language.page.belgium.fr')"></span></div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'set-language',
    data() {
        return {};
    }
};
</script>
