<template>
    <div class="c c-wizard__title text-center d-flex flex-column align-items-center justify-content-center" style="min-height: 150px;">
        <Balloons :brand="brand" />
        <h1 v-html="getTitle"></h1>
        <h2 class="c-wizard__title--sub text-primary" v-if="getSubtitle != ''" v-html="getSubtitle"></h2>
    </div>
</template>

<script>
import Balloons from '../general/balloons.vue';

export default {
    name: 'resultpage-title',
    data() {
        return {};
    },
    components: {
        Balloons
    },
    props: {
        brand: {
            type: String,
            required: true
        },
        promotions: {
            type: Array,
            required: false
        },
        promotionFoundInUrl: {
            type: Boolean,
            required: false
        }
    },
    computed: {
        getTitle() {
            if (this.promotionFoundInUrl && this.promotions[0] !== null && this.promotions[0].labelKey_ThankYouTitle !== '')
                return this.$tr(this.promotions[0].labelKey_ThankYouTitle);
            else return this.$tr('wizard.result.header.title');
        },
        getSubtitle() {
            if (this.promotionFoundInUrl && this.promotions[0] !== null) return '';
            // always an empty subtitle when it's a promotion
            else if (this.brand === 'etna') {
                return this.$tr('wizard.result.header.subtitle');
            }

            return '';
        }
    }
};
</script>
